.wd-accordion .accordion-item {
  margin-bottom: 1.1rem;
  border-color: transparent;
}

.wd-accordion .acc-header {
  background: var(--clr-secondary);
  padding: 0.8rem 3.8rem;
  padding-inline-start: 2rem;
  border-radius: 0.5rem;
}

  .wd-accordion .acc-header .svg-icon {
    width: 1rem;
  }

  .wd-accordion .acc-header .accordion-select-all {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

    .wd-accordion .acc-header .accordion-select-all label {
      font-size: 1.4rem;
      margin: 0;
    }

.wd-accordion .one-role {
  margin: 0;
  padding: 0.7rem 2.5rem;
  border: 0.1rem solid var(--clr-secondary);
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
}

  .wd-accordion .one-role:not(:last-child) {
    margin-bottom: 1rem;
  }



  /* OSAMA TEST TEST  */