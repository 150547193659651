/* For Future Updates Style */
.progress-container {
  &::before {
    content: "";
    background-color: var(--clr-primary);
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 4px;
    width: 100%;
    z-index: -1;
  }

  .progress {
    background-color: var(--clr-success);
    transform: translateY(-50%);
    z-index: -1;

    div {
      transition: 0.3s ease-in-out;
    }
  }

  .circle {
    background: var(--clr-primary);
    color: var(--clr-white);
    border: 3px solid var(--clr-primary);
    transition: 0.3s ease-in-out;

    .step {
      color: var(--clr-white);
    }

    .caption {
      bottom: -31px;
      color: var(--clr-body);
      transform: translate(45%, 0);
      text-align: center;
    }

    &.step-light {
      background-color: var(--clr-light-success);
      border-color: var(--clr-light-success);
    }
  }
}

.wd-card {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 15px;
  // cursor: pointer;
  min-height: 156px;
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.38);
}

.side-dahsed-border {
  border-left: 6px dashed var(--clr-secondary);
}

.line-2 {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 5px;
    height: 184px;
    inset-inline-end: 0px;
    bottom: -40px;
    background-image: url("./../media/icons/line-2.svg");
    background-repeat: no-repeat;
    background-position: center;
  }
}

.w-65 {
  width: 65%;
}

@media only screen and (max-width: 768px) {
  .auth-header-sm {
    // background-color: var(--clr-primary);
  }

  .w-sm-full {
    width: 100%;
  }

  .w-65 {
    width: 100%;
  }
}

@media only screen and (max-width: 400px) {
  .container {
    width: 85%;
  }

  .caption {
    font-size: 10px !important;
  }
}

.divider {
  height: 2px;
  width: 100%;
  border: 2px;
  background-color: var(--clr-primary);
  opacity: 0.6;
  margin-bottom: 35px;
}
