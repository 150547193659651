:root {
  --clr-body: #222333;
  --bg-body: #ffffff;
  --ff-body-ar: "IBM Plex Sans Arabic", sans-serif;
  --ff-body-en: "Poppins", sans-serif;
  --fw-body: 400;
  --clr-primary: #006a62;
  --clr-secondary: #7d8897;
  --clr-success: #73a87f;
  --clr-warning: #ceb06b;
  --clr-danger: #ee5b64;
  --clr-white: #fff;
  --clr-black: #000;
  --clr-dark: #1c1e1c;
  // Light Colors
  --clr-light-primary-1: rgba(0, 106, 98, 0.31);
  --clr-light-primary-2: rgba(0, 106, 98, 0.1);
  --clr-light-primary-3: rgba(0, 106, 98, 0.2);
  --clr-light-warning-1: rgba(206, 176, 107, 0.31);
  --clr-light-warning-2: rgba(206, 176, 107, 0.1);
  --clr-light-danger-1: #fff0f0;
  --clr-light-danger-2: rgba(255, 0, 0, 0.16);
  --clr-light-danger-3: rgba(238, 91, 100, 0.1);
  --clr-light-secondary-1: rgba(125, 136, 151, 0.1);
  --clr-light-success: rgb(110, 170, 166);

  --clr-gray: #e4e4e4;
  --clr-gray-1: rgba(185, 186, 208, 0.14);
  --clr-gray-2: #a1a5b7;
  --clr-gray-3: rgba(161, 165, 183, 0.3);
  --clr-gray-4: rgba(185, 186, 208, 0.1);
  --clr-gray-5: #f1f1f2;
  --clr-gray-6: #7e8299;

  --clr-red: #d94545;
  --clr-red-2: #e24c4b;

  --clr-blue: #5891ff;

  --clr-orange: #ff9900;

  --fs-xs: 13px;
  --fs-sm: 14px;
  --fs-md: 16px;
  --fs-lg: 18px;
  --fs-xl: 18px;
  --fs-xxl: 20px;

  --fs-xs-larg: 11px;
  --fs-sm-larg: 12px;
  --fs-md-larg: 14px;
  --fs-lg-larg: 16px;
  --fs-xl-larg: 16px;
  --fs-xxl-larg: 18px;

  --fs-xs-smal: 11px;
  --fs-sm-smal: 12px;
  --fs-md-smal: 14px;
  --fs-lg-smal: 16px;
  --fs-xl-smal: 16px;
  --fs-xxl-smal: 18px;
}
