/* Variables */
.btn {
  --btn-padding-block: 5px;
  /* width */
  --btn-padding-inline-sm: 10px;
  --btn-padding-inline-md: 20px;
  --btn-padding-inline-lg: 30px;
  --btn-padding-inline-xl: 40px;
  /* height */
  --btn-height-sm: 30px;
  --btn-height-md: 40px;
  --btn-height-lg: 50px;
  --btn-height-xl: 60px;
  /* font */
  --btn-fs-sm:14px;
  --btn-fs-md:16px;
  --btn-fs-lg:20px;
  --btn-fs-xl:24px;
  --btn-fw: bold;
  --btn-border-radius: 5px;
  /* colors */
  --btn-clr: var(--clr-dark);
  --btn-bg: var(--clr-secondary);
  --btn-border-clr: var(--clr-gray);
  /* opacity */
  --btn-opacity-hover: 0.4;
  --btn-opacity-disabled: 0.2;
  /* --- --- */
  /* icons||only */
  --btn-icon-padding: 5px;
  --btn-icon-border-radius: 5px;
  --btn-icon-fs: 12px;
  /* icon sizes */
  --btn-icon-width-sm: 20px;
  --btn-icon-height-sm: 20px;
  --btn-icon-width-md: 30px;
  --btn-icon-height-md: 30px;
  --btn-icon-width-lg: 40px;
  --btn-icon-height-lg: 40px;
  --btn-icon-width-xl: 50px;
  --btn-icon-height-xl: 50px;

}
/* ==== === ==== */
/* Prefix */
.btn.wd-btn{
  --btn-padding-block: 10px;
  --btn-padding-inline-md: 20px;
  /* height */
  --btn-height-sm: 35px;
  --btn-height-md: 48px;
  --btn-height-lg: 60px;
  --btn-height-xl: 73px;
  /* font size */
  --btn-fs-sm:13px;
  --btn-fs-md:16px;
  --btn-fs-lg:20px;
  --btn-fs-xl:24px;
  --btn-fw: 700;
  --btn-border-radius: 40vw;
  /* icons */
  --btn-icon-padding: 5px;
  --btn-icon-width-md: 28px;
  --btn-icon-height-md: 28px;
  --btn-icon-border-radius: 40vw;
  --btn-icon-fs: 16px;
   /* icon sizes */
   --btn-icon-width-sm: 35px;
   --btn-icon-height-sm: 35px;
   --btn-icon-width-md: 30px;
   --btn-icon-height-md: 30px;
   --btn-icon-width-lg: 60px;
   --btn-icon-height-lg: 60px;
   --btn-icon-width-xl: 73px;
   --btn-icon-height-xl: 73px;
  /* opacity */
  --btn-opacity-hover: 0.5;
  --btn-opacity-disabled: 0.3;
}
.btn.wd-btn:not(.btn.btn-transparent , .btn.btn-icon){
  line-height: 26px;
  min-width: 10rem;
}
.btn.wd-btn.btn-sm:not(.btn.btn-transparent , .btn.btn-icon){line-height: 13px;}
/* ==== === ==== */
/* General Style */
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-align: center;
  text-decoration: none;
  border: 1px solid transparent;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  /*---*/
  padding: var(--btn-padding-block) var(--btn-padding-inline-md);
  height: var(--btn-height-md);
  font-size: var(--btn-fs);
  font-weight: var(--btn-fw);
  border-radius: var(--btn-border-radius);
  color: var(--btn-clr);
  background-color: var(--btn-bg);
  border-color: var(--btn-border-clr);
}
.btn:hover:not(.btn-link) {color: var(--btn-clr); background-color: var(--btn-bg); border-color: var(--btn-border-clr); opacity: var(--btn-opacity-hover);}
.btn:focus,
.btn:focus-visible {color: var(--btn-clr); background-color: var(--btn-bg); border-color: var(--btn-border-clr); outline: 0; box-shadow: none;}
.btn:disabled {opacity: var(--btn-opacity-disabled); pointer-events: none;}
/* Buttons || Sizes */
.btn.btn-sm {padding-inline: var(--btn-padding-inline-sm); height: var(--btn-height-sm); font-size: var(--btn-fs-sm);}
.btn.btn-md {padding-inline: var(--btn-padding-inline-md); height: var(--btn-height-md); font-size: var(--btn-fs-md);}
.btn.btn-lg {padding-inline: var(--btn-padding-inline-lg); height: var(--btn-height-lg); font-size: var(--btn-fs-lg);}
.btn.btn-xl {padding-inline: var(--btn-padding-inline-xl); height: var(--btn-height-xl); font-size: var(--btn-fs-xl);}
/* Buttons || Colors */
.btn.btn-primary {color: var(--clr-white) !important; background-color: var(--clr-primary) !important; border-color: var(--clr-primary) !important;}
.btn.btn-secondary {color: var(--clr-dark) !important; background-color: var(--clr-secondary) !important; border-color: var(--clr-secondary) !important;}
.btn.btn-success {color: var(--clr-white) !important; background-color: var(--clr-success) !important; border-color: var(--clr-success) !important;}
.btn.btn-warning {color: var(--clr-white) !important; background-color: var(--clr-warning) !important; border-color: var(--clr-warning) !important;}
.btn.btn-danger {color: var(--clr-white) !important; background-color: var(--clr-danger) !important; border-color: var(--clr-danger) !important;}
/* Buttons || Custom Colors */
.btn-light-primary{color: var(--clr-primary) !important; background-color: var(--clr-light-primary-1) !important; border-color: transparent !important;}
.btn-light-primary-2{color: var(--clr-primary) !important; background-color: var(--clr-light-primary-2) !important; border-color: transparent !important;}
.btn-light-warning{color: var(--clr-warning) !important; background-color: var(--clr-light-warning-1) !important; border-color: transparent !important;}
.btn-light-warning-2{color: var(--clr-warning) !important; background-color: var(--clr-light-warning-2) !important; border-color: transparent !important;}
.btn-secondary-2{color: var(--clr-body) !important; background-color: var(--clr-secondary-2) !important; border-color: transparent !important;}
.btn-light-gray{color: var(--clr-body) !important; background-color: var(--clr-gray-5) !important; border-color: var(--clr-gray-5) !important;}
/* Buttons || Icons - only */
.btn.btn-icon {width: var(--btn-icon-width-md); height: var(--btn-icon-height-md); padding: var(--btn-icon-padding); border-radius: var(--btn-icon-border-radius); font-size: var(--btn-icon-fs); line-height: 1.5;}
/* Buttons || Icons - sizes */
.btn.btn-icon.btn-sm {width: var(--btn-icon-width-sm); height: var(--btn-icon-height-sm);}
.btn.btn-icon.btn-md {width: var(--btn-icon-width-md); height: var(--btn-icon-height-md);}
.btn.btn-icon.btn-lg {width: var(--btn-icon-width-lg); height: var(--btn-icon-height-lg);}
.btn.btn-icon.btn-xl {width: var(--btn-icon-width-xl); height: var(--btn-icon-height-xl);}
/* When Using btn-tranparent | Dont use .btn-color => use .text-color */
.btn.btn-link,
.btn.btn-transparent:hover,
.btn.btn-transparent:focus-visible,
.btn.btn-transparent{padding: 0; background: transparent; border: transparent; height: auto; width: auto;}
.btn.btn-link:hover{text-decoration: underline;}
/* ==== === ==== */
/* .btn:not(.ngb-dp-arrow-btn) */
/* ==== === ==== */
/*  Custom Classes */
.btn-outline {
  background: transparent;
  color: var(--btn-clr);
  border-color: var(--btn-clr);
  border-radius: 0.5rem;
}
/* colors */
.btn-outline.btn-primary{color: var(--clr-primary) !important; background-color: transparent !important; border-color: var(--clr-primary) !important;}
.btn-outline.btn-secondary{color: var(--clr-secondary) !important; background-color: transparent !important; border-color: var(--clr-secondary) !important;}
.btn-outline.btn-success{color: var(--clr-success) !important; background-color: transparent !important; border-color: var(--clr-success) !important;}
.btn-outline.btn-warning{color: var(--clr-warning) !important; background-color: transparent !important; border-color: var(--clr-warning) !important;}
.btn-outline.btn-danger{color: var(--clr-danger) !important; background-color: transparent !important; border-color: var(--clr-danger) !important;}
.btn-outline.btn-red{color: var(--clr-red-2) !important; background-color: transparent !important; border-color: var(--clr-red-2) !important;}
.btn-outline.btn-blue{color: var(--clr-blue) !important; background-color: transparent !important; border-color: var(--clr-blue) !important;}

.btn.btn-view{
  border-radius: 8px;
  background-color: var(--clr-gray-5) !important;
  border-color: var(--clr-gray-5) !important;
  color: var(--clr-gray-6) !important;
}

.sort-icon {
  height: 10px;
  width: 30px;
}

@media screen and (max-width: 400px) {
  .w-fit-content {
    min-width: fit-content !important;
  }
}