/* Theme variables */
/* @import "../../assets/css/_theme-variables.css"; */
@import "./_theme-variables";
@import "./fonts/fonts";
@import "./layout/_layout";
@import "./components/_variables";
/*------------*/
/* Reset Style */
/*=== ===*/
/* NEW */
*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  margin: 0;
  padding: 0;
  font: inherit;
}
html {
  color-scheme: dark light;
}
img,
picture,
video {
  display: block;
  max-width: 100%;
}
ul {
  list-style: none;
  padding: 0;
}
a {
  text-decoration: none;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
}
a:-webkit-any-link:focus-visible {
  outline: 0px;
}
p {
  margin: 0;
}
.invest-alert {
  width: fit-content;
  background-color: #ffeeba;
  color: #856404 !important;
  text-align: justify;
}
.invest-card {
  width: 100% !important;
}
.invest-row {
  width: 100% !important;
}
.invest-body {
  margin-right: 1rem !important;
  display: flex !important ;
  width: 100% !important;
  align-items: center !important;
  justify-content: center !important;
}
.alert-add-account {
  display: flex !important;
  align-items: center;
  flex-direction: column !important;
}

/*** OR  ***/
input,
textarea,
button,
select {
  font: inherit;
}
/*=== ===*/

/* ------------------- */
/* body, h1, h2, h3, h4, h5, h6, p {margin: 0;}
a {text-decoration: none; transition: 0.2s all ease-in-out;cursor: pointer;}
a:-webkit-any-link:focus-visible{outline: 0px;}
p {margin: 0;}
ul{list-style: none; padding: 0; margin: 0;} */

/* Body | RTL */
body {
  /* min-height: 100vh; */
  color: var(--clr-body);
  background: var(--bg-body);
}
[dir="rtl"] body {
  font-family: var(--ff-body-ar);
}
[dir="ltr"] body {
  font-family: var(--ff-body-en);
}
/*------------*/
/* Spaceing Classes */
/*margin*/
.m-0 {
  margin: 0 !important;
}
.m-5 {
  margin: 5px !important;
}
.m-10 {
  margin: 10px !important;
}
.m-15 {
  margin: 15px !important;
}
.m-20 {
  margin: 20px !important;
}
.m-25 {
  margin: 25px !important;
}
.m-30 {
  margin: 30px !important;
}
.m-35 {
  margin: 35px !important;
}
.m-40 {
  margin: 40px !important;
}
.m-45 {
  margin: 45px !important;
}
.m-50 {
  margin: 50px !important;
}
.m-55 {
  margin: 55px !important;
}
.m-60 {
  margin: 60px !important;
}
.m-65 {
  margin: 65px !important;
}
.m-70 {
  margin: 70px !important;
}
.m-75 {
  margin: 75px !important;
}
.m-80 {
  margin: 80px !important;
}
.m-85 {
  margin: 85px !important;
}
.m-90 {
  margin: 90px !important;
}
.m-95 {
  margin: 95px !important;
}
.m-100 {
  margin: 100px !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-5 {
  margin-top: 5px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-15 {
  margin-top: 15px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-25 {
  margin-top: 25px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mt-35 {
  margin-top: 35px !important;
}
.mt-40 {
  margin-top: 40px !important;
}
.mt-45 {
  margin-top: 45px !important;
}
.mt-50 {
  margin-top: 50px !important;
}
.mt-55 {
  margin-top: 55px !important;
}
.mt-60 {
  margin-top: 60px !important;
}
.mt-65 {
  margin-top: 65px !important;
}
.mt-70 {
  margin-top: 70px !important;
}
.mt-75 {
  margin-top: 75px !important;
}
.mt-80 {
  margin-top: 80px !important;
}
.mt-85 {
  margin-top: 85px !important;
}
.mt-90 {
  margin-top: 90px !important;
}
.mt-95 {
  margin-top: 95px !important;
}
.mt-100 {
  margin-top: 100px !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-25 {
  margin-bottom: 25px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-35 {
  margin-bottom: 35px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}
.mb-45 {
  margin-bottom: 45px !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}
.mb-55 {
  margin-bottom: 55px !important;
}
.mb-60 {
  margin-bottom: 60px !important;
}
.mb-65 {
  margin-bottom: 65px !important;
}
.mb-70 {
  margin-bottom: 70px !important;
}
.mb-75 {
  margin-bottom: 75px !important;
}
.mb-80 {
  margin-bottom: 80px !important;
}
.mb-85 {
  margin-bottom: 85px !important;
}
.mb-90 {
  margin-bottom: 90px !important;
}
.mb-95 {
  margin-bottom: 95px !important;
}
.mb-100 {
  margin-bottom: 100px !important;
}
.my-0 {
  margin-block: 0 !important;
}
.my-5 {
  margin-block: 5px !important;
}
.my-10 {
  margin-block: 10px !important;
}
.my-15 {
  margin-block: 15px !important;
}
.my-20 {
  margin-block: 20px !important;
}
.my-25 {
  margin-block: 25px !important;
}
.my-30 {
  margin-block: 30px !important;
}
.my-35 {
  margin-block: 35px !important;
}
.my-40 {
  margin-block: 40px !important;
}
.my-45 {
  margin-block: 45px !important;
}
.my-50 {
  margin-block: 50px !important;
}
.my-55 {
  margin-block: 55px !important;
}
.my-60 {
  margin-block: 60px !important;
}
.my-65 {
  margin-block: 65px !important;
}
.my-70 {
  margin-block: 70px !important;
}
.my-75 {
  margin-block: 75px !important;
}
.my-80 {
  margin-block: 80px !important;
}
.my-85 {
  margin-block: 85px !important;
}
.my-90 {
  margin-block: 90px !important;
}
.my-95 {
  margin-block: 95px !important;
}
.my-100 {
  margin-block: 100px !important;
}
.ms-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-inline-start: 0 !important;
}
.ms-5 {
  margin-left: 0 !important;
  margin-right: 0px !important;
  margin-inline-start: 5px !important;
}
.ms-10 {
  margin-inline-start: 10px !important;
}
.ms-15 {
  margin-inline-start: 15px !important;
}
.ms-20 {
  margin-inline-start: 20px !important;
}
.ms-25 {
  margin-inline-start: 25px !important;
}
.ms-30 {
  margin-inline-start: 30px !important;
}
.ms-35 {
  margin-inline-start: 35px !important;
}
.ms-40 {
  margin-inline-start: 40px !important;
}
.ms-45 {
  margin-inline-start: 45px !important;
}
.ms-50 {
  margin-inline-start: 50px !important;
}
.ms-55 {
  margin-inline-start: 55px !important;
}
.ms-60 {
  margin-inline-start: 60px !important;
}
.ms-65 {
  margin-inline-start: 65px !important;
}
.ms-70 {
  margin-inline-start: 70px !important;
}
.ms-75 {
  margin-inline-start: 75px !important;
}
.ms-80 {
  margin-inline-start: 80px !important;
}
.ms-85 {
  margin-inline-start: 85px !important;
}
.ms-90 {
  margin-inline-start: 90px !important;
}
.ms-95 {
  margin-inline-start: 95px !important;
}
.ms-100 {
  margin-inline-start: 100px !important;
}
.me-0 {
  margin-inline-end: 0 !important;
}
.me-5 {
  margin-right: 0 !important;
  margin-left: 0 !important;
  margin-inline-end: 5px !important;
}
.me-10 {
  margin-inline-end: 10px !important;
}
.me-15 {
  margin-inline-end: 15px !important;
}
.me-20 {
  margin-inline-end: 20px !important;
}
.me-25 {
  margin-inline-end: 25px !important;
}
.me-30 {
  margin-inline-end: 30px !important;
}
.me-35 {
  margin-inline-end: 35px !important;
}
.me-40 {
  margin-inline-end: 40px !important;
}
.me-45 {
  margin-inline-end: 45px !important;
}
.me-50 {
  margin-inline-end: 50px !important;
}
.me-55 {
  margin-inline-end: 55px !important;
}
.me-60 {
  margin-inline-end: 60px !important;
}
.me-65 {
  margin-inline-end: 65px !important;
}
.me-70 {
  margin-inline-end: 70px !important;
}
.me-75 {
  margin-inline-end: 75px !important;
}
.me-80 {
  margin-inline-end: 80px !important;
}
.me-85 {
  margin-inline-end: 85px !important;
}
.me-90 {
  margin-inline-end: 90px !important;
}
.me-95 {
  margin-inline-end: 95px !important;
}
.me-100 {
  margin-inline-end: 100px !important;
}
.mx-0 {
  margin-inline: 0 !important;
}
.mx-5 {
  margin-inline: 5px !important;
}
.mx-10 {
  margin-inline: 10px !important;
}
.mx-15 {
  margin-inline: 15px !important;
}
.mx-20 {
  margin-inline: 20px !important;
}
.mx-25 {
  margin-inline: 25px !important;
}
.mx-30 {
  margin-inline: 30px !important;
}
.mx-35 {
  margin-inline: 35px !important;
}
.mx-40 {
  margin-inline: 40px !important;
}
.mx-45 {
  margin-inline: 45px !important;
}
.mx-50 {
  margin-inline: 50px !important;
}
.mx-55 {
  margin-inline: 55px !important;
}
.mx-60 {
  margin-inline: 60px !important;
}
.mx-65 {
  margin-inline: 65px !important;
}
.mx-70 {
  margin-inline: 70px !important;
}
.mx-75 {
  margin-inline: 75px !important;
}
.mx-80 {
  margin-inline: 80px !important;
}
.mx-85 {
  margin-inline: 85px !important;
}
.mx-90 {
  margin-inline: 90px !important;
}
.mx-95 {
  margin-inline: 95px !important;
}
.mx-100 {
  margin-inline: 100px !important;
}
/* ---- */
/* @media screen and (min-width: 992px) {
  .m-d-0{margin: 0 !important;}
  .mt-d-40{margin-top: 4rem !important;}
} */
/* @media screen and (max-width: 800.98px){.mt-m-10{margin-top: 1rem;}} */
/* ---- */
/*padding*/
.p-0 {
  padding: 0 !important;
}
.p-5 {
  padding: 5px !important;
}
.p-10 {
  padding: 10px !important;
}
.p-15 {
  padding: 15px !important;
}
.p-20 {
  padding: 20px !important;
}
.p-25 {
  padding: 25px !important;
}
.p-30 {
  padding: 30px !important;
}
.p-35 {
  padding: 35px !important;
}
.p-40 {
  padding: 40px !important;
}
.p-45 {
  padding: 45px !important;
}
.p-50 {
  padding: 50px !important;
}
.p-55 {
  padding: 55px !important;
}
.p-60 {
  padding: 60px !important;
}
.p-65 {
  padding: 65px !important;
}
.p-70 {
  padding: 70px !important;
}
.p-75 {
  padding: 75px !important;
}
.p-80 {
  padding: 80px !important;
}
.p-85 {
  padding: 85px !important;
}
.p-90 {
  padding: 90px !important;
}
.p-95 {
  padding: 95px !important;
}
.p-100 {
  padding: 100px !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pt-5 {
  padding-top: 5px !important;
}
.pt-10 {
  padding-top: 10px !important;
}
.pt-15 {
  padding-top: 15px !important;
}
.pt-20 {
  padding-top: 20px !important;
}
.pt-25 {
  padding-top: 25px !important;
}
.pt-30 {
  padding-top: 30px !important;
}
.pt-35 {
  padding-top: 35px !important;
}
.pt-40 {
  padding-top: 40px !important;
}
.pt-45 {
  padding-top: 45px !important;
}
.pt-50 {
  padding-top: 50px !important;
}
.pt-55 {
  padding-top: 55px !important;
}
.pt-60 {
  padding-top: 60px !important;
}
.pt-65 {
  padding-top: 65px !important;
}
.pt-70 {
  padding-top: 70px !important;
}
.pt-75 {
  padding-top: 75px !important;
}
.pt-80 {
  padding-top: 80px !important;
}
.pt-85 {
  padding-top: 85px !important;
}
.pt-90 {
  padding-top: 90px !important;
}
.pt-95 {
  padding-top: 95px !important;
}
.pt-100 {
  padding-top: 100px !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pb-5 {
  padding-bottom: 5px !important;
}
.pb-10 {
  padding-bottom: 10px !important;
}
.pb-15 {
  padding-bottom: 15px !important;
}
.pb-20 {
  padding-bottom: 20px !important;
}
.pb-25 {
  padding-bottom: 25px !important;
}
.pb-30 {
  padding-bottom: 30px !important;
}
.pb-35 {
  padding-bottom: 35px !important;
}
.pb-40 {
  padding-bottom: 40px !important;
}
.pb-45 {
  padding-bottom: 45px !important;
}
.pb-50 {
  padding-bottom: 50px !important;
}
.pb-55 {
  padding-bottom: 55px !important;
}
.pb-60 {
  padding-bottom: 60px !important;
}
.pb-65 {
  padding-bottom: 65px !important;
}
.pb-70 {
  padding-bottom: 70px !important;
}
.pb-75 {
  padding-bottom: 75px !important;
}
.pb-80 {
  padding-bottom: 80px !important;
}
.pb-85 {
  padding-bottom: 85px !important;
}
.pb-90 {
  padding-bottom: 90px !important;
}
.pb-95 {
  padding-bottom: 95px !important;
}
.pb-100 {
  padding-bottom: 100px !important;
}
.py-0 {
  padding-block: 0 !important;
}
.py-5 {
  padding-block: 5px !important;
}
.py-10 {
  padding-block: 10px !important;
}
.py-15 {
  padding-block: 15px !important;
}
.py-20 {
  padding-block: 20px !important;
}
.py-25 {
  padding-block: 25px !important;
}
.py-30 {
  padding-block: 30px !important;
}
.py-35 {
  padding-block: 35px !important;
}
.py-40 {
  padding-block: 40px !important;
}
.py-45 {
  padding-block: 45px !important;
}
.py-50 {
  padding-block: 50px !important;
}
.py-55 {
  padding-block: 55px !important;
}
.py-60 {
  padding-block: 60px !important;
}
.py-65 {
  padding-block: 65px !important;
}
.py-70 {
  padding-block: 70px !important;
}
.py-75 {
  padding-block: 75px !important;
}
.py-80 {
  padding-block: 80px !important;
}
.py-85 {
  padding-block: 85px !important;
}
.py-90 {
  padding-block: 90px !important;
}
.py-95 {
  padding-block: 95px !important;
}
.py-100 {
  padding-block: 100px !important;
}
.ps-0 {
  padding-inline-start: 0 !important;
}
.ps-5 {
  padding-inline-start: 5px !important;
}
.ps-10 {
  padding-inline-start: 10px !important;
}
.ps-15 {
  padding-inline-start: 15px !important;
}
.ps-20 {
  padding-inline-start: 20px !important;
}
.ps-25 {
  padding-inline-start: 25px !important;
}
.ps-30 {
  padding-inline-start: 30px !important;
}
.ps-35 {
  padding-inline-start: 35px !important;
}
.ps-40 {
  padding-inline-start: 40px !important;
}
.ps-45 {
  padding-inline-start: 45px !important;
}
.ps-50 {
  padding-inline-start: 50px !important;
}
.ps-55 {
  padding-inline-start: 55px !important;
}
.ps-60 {
  padding-inline-start: 60px !important;
}
.ps-65 {
  padding-inline-start: 65px !important;
}
.ps-70 {
  padding-inline-start: 70px !important;
}
.ps-75 {
  padding-inline-start: 75px !important;
}
.ps-80 {
  padding-inline-start: 80px !important;
}
.ps-85 {
  padding-inline-start: 85px !important;
}
.ps-90 {
  padding-inline-start: 90px !important;
}
.ps-95 {
  padding-inline-start: 95px !important;
}
.ps-100 {
  padding-inline-start: 100px !important;
}
.pe-0 {
  padding-inline-end: 0 !important;
}
.pe-5 {
  padding-inline-end: 5px !important;
}
.pe-10 {
  padding-inline-end: 10px !important;
}
.pe-15 {
  padding-inline-end: 15px !important;
}
.pe-20 {
  padding-inline-end: 20px !important;
}
.pe-25 {
  padding-inline-end: 25px !important;
}
.pe-30 {
  padding-inline-end: 30px !important;
}
.pe-35 {
  padding-inline-end: 35px !important;
}
.pe-40 {
  padding-inline-end: 40px !important;
}
.pe-45 {
  padding-inline-end: 45px !important;
}
.pe-50 {
  padding-inline-end: 50px !important;
}
.pe-55 {
  padding-inline-end: 55px !important;
}
.pe-60 {
  padding-inline-end: 60px !important;
}
.pe-65 {
  padding-inline-end: 65px !important;
}
.pe-70 {
  padding-inline-end: 70px !important;
}
.pe-75 {
  padding-inline-end: 75px !important;
}
.pe-80 {
  padding-inline-end: 80px !important;
}
.pe-85 {
  padding-inline-end: 85px !important;
}
.pe-90 {
  padding-inline-end: 90px !important;
}
.pe-95 {
  padding-inline-end: 95px !important;
}
.pe-100 {
  padding-inline-end: 100px !important;
}
.px-0 {
  padding-inline: 0 !important;
}
.px-5 {
  padding-inline: 5px !important;
}
.px-10 {
  padding-inline: 10px !important;
}
.px-15 {
  padding-inline: 15px !important;
}
.px-20 {
  padding-inline: 20px !important;
}
.px-25 {
  padding-inline: 25px !important;
}
.px-30 {
  padding-inline: 30px !important;
}
.px-35 {
  padding-inline: 35px !important;
}
.px-40 {
  padding-inline: 40px !important;
}
.px-45 {
  padding-inline: 45px !important;
}
.px-50 {
  padding-inline: 50px !important;
}
.px-55 {
  padding-inline: 55px !important;
}
.px-60 {
  padding-inline: 60px !important;
}
.px-65 {
  padding-inline: 65px !important;
}
.px-70 {
  padding-inline: 70px !important;
}
.px-75 {
  padding-inline: 75px !important;
}
.px-80 {
  padding-inline: 80px !important;
}
.px-85 {
  padding-inline: 85px !important;
}
.px-90 {
  padding-inline: 90px !important;
}
.px-95 {
  padding-inline: 95px !important;
}
.px-100 {
  padding-inline: 100px !important;
}
/*------------*/
/* Text Classes */
/* Font Size */
.fs-20 {
  font-size: var(--fs-xxl) !important;
}

.fs-18 {
  font-size: var(--fs-lg) !important;
}

.fs-16 {
  font-size: var(--fs-md) !important;
}

/* .fs-sm{font-size: clamp(--fs-min, 3vw, --fs-max) !important;} */
.fs-xs {
  font-size: var(--fs-xs) !important;
}
.fs-sm {
  font-size: var(--fs-sm) !important;
}
.fs-md {
  font-size: var(--fs-md) !important;
}
.fs-lg {
  font-size: var(--fs-lg) !important;
}
.fs-xl {
  font-size: var(--fs-xl) !important;
}
.fs-xxl {
  font-size: var(--fs-xxl) !important;
}
/* ---- */
/* Font Weight */
.fw-lighter {
  font-weight: 100 !important;
}
.fw-light {
  font-weight: 300 !important;
}
.fw-normal {
  font-weight: 400 !important;
}
.fw-medium {
  font-weight: 500 !important;
}
.fw-semibold {
  font-weight: 600 !important;
}
.fw-bold {
  font-weight: 700 !important;
}
.fw-extrabold {
  font-weight: 800 !important;
}
.fw-heavy {
  font-weight: 900 !important;
}
/* ---- */
/* Text Align */
.text-start {
  text-align: start !important;
}
.text-end {
  text-align: end !important;
}
/* ---- */
/* Text Color */
.text-primary {
  color: var(--clr-primary) !important;
}
.text-secondary {
  color: var(--clr-secondary) !important;
}
.text-success {
  color: var(--clr-success) !important;
}
.text-warning {
  color: var(--clr-warning) !important;
}
.text-danger {
  color: var(--clr-danger) !important;
}
.text-white {
  color: var(--clr-white) !important;
}
.text-gray {
  color: var(--clr-gray) !important;
}
.text-black {
  color: var(--clr-black) !important;
}
.text-dark {
  color: var(--clr-dark) !important;
}
.text-orange {
  color: var(--clr-orange) !important;
}
// light colors
.text-light-primary {
  color: var(--clr-light-primary-1) !important;
}
.text-light-primary-2 {
  color: var(--clr-light-primary-2) !important;
}
.text-light-warning {
  color: var(--clr-light-warning-1) !important;
}
.text-light-warning-2 {
  color: var(--clr-light-warning-2) !important;
}
.text-red-2 {
  color: var(--clr-red-2) !important;
}
/* ---- */
/* Background Color */
.bg-primary {
  background-color: var(--clr-primary) !important;
}
.bg-secondary {
  background-color: var(--clr-secondary) !important;
}
.bg-success {
  background-color: var(--clr-success) !important;
}
.bg-warning {
  background-color: var(--clr-warning) !important;
}
.bg-danger {
  background-color: var(--clr-danger) !important;
}
.bg-white {
  background-color: var(--clr-white) !important;
}
.bg-black {
  background-color: var(--clr-black) !important;
}
.bg-gray {
  background-color: var(--clr-gray) !important;
}

.bg-transparent {
  background-color: transparent !important;
}
.bg-secondary-3 {
  background-color: var(--clr-secondary-3) !important;
}
.bg-info {
  background-color: var(--clr-info) !important;
}
.bg-light {
  background-color: var(--clr-light) !important;
}
.bg-gray {
  background-color: var(--clr-gray) !important;
}
.bg-gray-1 {
  background-color: var(--clr-gray-1) !important;
}
.bg-gray-2 {
  background-color: var(--clr-gray-2) !important;
}
.bg-light-orange {
  background: var(--clr-light-orange);
}
.bg-light-primary-3 {
  background: var(--clr-light-primary-3);
}
.bg-light-primary-4 {
  background: var(--clr-light-primary-4);
}
/*------------*/
/* Borders */
.border {
  border: 1px solid rgba(0, 0, 0, 0.38) !important;
}
.border-start {
  border-inline-start: 1px solid !important;
}
.border-end {
  border-inline-end: 1px solid !important;
}
.border-top {
  border-block-start: 1px solid !important;
}
.border-bottom {
  border-block-end: 1px solid !important;
}
/* ---- */
.border-primary {
  border-color: var(--clr-primary) !important;
}
.border-secondary {
  border-color: var(--clr-secondary) !important;
}
.border-success {
  border-color: var(--clr-success) !important;
}
.border-warning {
  border-color: var(--clr-warning) !important;
}
.border-danger {
  border-color: var(--clr-danger) !important;
}
.border-white {
  border-color: var(--clr-white) !important;
}
.border-black {
  border-color: var(--clr-black) !important;
}
.border-secondary-5 {
  border-color: var(--clr-secondary-5) !important;
}
.border-light {
  border-color: var(--clr-light) !important;
}
.border-info {
  border-color: var(--clr-info) !important;
}
.border-gray {
  border-color: var(--clr-gray) !important;
}
/*------------*/
/* Border Radius */
.border-r-0 {
  border-radius: 0 !important;
}
.border-se-r-0 {
  border-start-end-radius: 0 !important;
}
.border-ss-r-0 {
  border-start-start-radius: 0 !important;
}
.border-es-r-0 {
  border-end-start-radius: 0 !important;
}
.border-ee-r-0 {
  border-end-end-radius: 0 !important;
}
.border-r-5 {
  border-radius: 5px !important;
}
.border-r-10 {
  border-radius: 10px !important;
}
.border-pill {
  border-radius: 40vw !important;
}
.border-rounded {
  border-radius: 50% !important;
}
/*------------*/
/* SVG */
.svg-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
/* SVG | COLORS */
.svg-primary {
  color: var(--clr-primary) !important;
}
.svg-light-primary {
  color: var(--clr-light-blue) !important;
}
.svg-secondary {
  color: var(--clr-secondary) !important;
}
.svg-success {
  color: var(--clr-success) !important;
}
.svg-warning {
  color: var(--clr-warning) !important;
}
.svg-orange {
  color: var(--clr-orange) !important;
}
.svg-danger {
  color: var(--clr-danger) !important;
}
.svg-dark {
  color: var(--clr-body) !important;
}
.svg-bg-white {
  background: var(--clr-white);
  border-radius: 0.5rem;
}
.svg-icon.svg-white svg [fill] {
  fill: white;
}
/* SVG | SIZES */
.svg-18 {
  width: 1.8rem;
  height: 1.8rem;
}
.svg-50,
.svg-50 svg {
  width: 5rem;
  height: 5rem;
}
/* Custom width only */
.svg-icon.svg-w-13 svg {
  width: 1.3rem !important;
  height: auto !important;
}
.svg-icon.svg-w-16 svg {
  width: 1.6rem !important;
  height: auto !important;
}
.svg-icon.svg-w-18 svg {
  width: 1.8rem !important;
  height: auto !important;
}
.svg-icon.svg-w-20 svg {
  width: 2rem !important;
  height: auto !important;
}
.svg-icon.svg-w-21 svg {
  width: 2.1rem !important;
  height: auto !important;
}
.svg-icon.svg-w-22 svg {
  width: 2.2rem !important;
  height: auto !important;
}
.svg-icon.svg-w-23 svg {
  width: 2.3rem !important;
  height: auto !important;
}
.svg-icon.svg-w-25 svg {
  width: 2.5rem !important;
  height: auto !important;
}
.svg-icon.svg-w-27 svg {
  width: 2.7rem !important;
  height: auto !important;
}
.svg-icon.svg-w-28 svg {
  width: 2.8rem !important;
  height: auto !important;
}
.svg-icon.svg-w-29 svg {
  width: 2.9rem !important;
  height: auto !important;
}
.svg-icon.svg-w-30 svg {
  width: 3rem !important;
  height: auto !important;
}
.svg-icon.svg-w-31 svg {
  width: 3.1rem !important;
  height: auto !important;
}
.svg-icon.svg-w-32 svg {
  width: 3.2rem !important;
  height: auto !important;
}
.svg-icon.svg-w-35 svg {
  width: 3.5rem !important;
  height: auto !important;
}
.svg-icon.svg-w-40 svg {
  width: 4rem !important;
  height: auto !important;
}
.svg-icon.svg-w-45 svg {
  width: 4.5rem !important;
  height: auto !important;
}
.svg-icon.svg-w-56 svg {
  width: 5.6rem !important;
  height: auto !important;
}
.svg-icon.svg-w-88 svg {
  width: 8.8rem !important;
  height: auto !important;
}
.svg-icon.svg-w-90 svg {
  width: 9rem !important;
  height: auto !important;
}
.w-fit {
  width: fit-content;
}
/* Svg Custom */
.svg-icon.svg-icon-2-border {
  background: var(--clr-primary);
  box-shadow: 0 0 0 0.3rem var(--clr-white), 0 0 0 0.5rem var(--clr-primary);
}
img {
  width: 100%;
}

/* NEW  */
/* Fixing SVG Height */
.label .svg-icon .svg-inline--fa {
  height: auto;
  min-height: 1em;
}
/* ====================== */
/* Custom Classes */
.wd-scroll {
  width: 100%;
  height: calc(100vh - 370px);
  object-fit: contain;
  overflow: hidden;
  margin-bottom: 20px;
  overflow-y: auto;
  direction: ltr;
  text-align: right;
  padding: 10px;
  text-align: justify;
}
.wd-scroll {
  scrollbar-width: auto;
  scrollbar-color: #d9d9d9 transparent;
}
.wd-scroll::-webkit-scrollbar {
  width: 1.8rem;
}
.wd-scroll::-webkit-scrollbar-track {
  background: rgba(217, 217, 217, 0.33);
  border-radius: 20px;
}
.wd-scroll::-webkit-scrollbar-thumb {
  border: 4px solid transparent;
  background-color: #d9d9d9;
  border-radius: 20px;
  background-clip: content-box;
}

/* ====================== */
/*------------*/
/* Custom Classes */
.dir-ltr {
  direction: ltr;
}
.wd-row {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.wd-row.col-20 > * {
  width: 20%;
  min-width: 20rem;
  flex: 20% 0 0%;
}
.cursor-pointer {
  cursor: pointer;
}
.no-events {
  pointer-events: none !important;
}
.separator {
  width: 100%;
  height: 0.1rem;
  background: var(--clr-gray);
}
.separator-outer {
  width: calc(100% + 4rem);
  margin-inline-start: -2rem;
}
.h-calc-7 {
  height: calc(100% - 7rem) !important;
}
.h-calc-20 {
  height: calc(100% - 2rem) !important;
}
.h-40 {
  height: 4rem !important;
}
.lh-25 {
  line-height: 2.5rem;
}
.lh-28 {
  line-height: 2.8rem;
}
.max-w-200 {
  max-width: 20rem !important;
}
.min-w-200 {
  min-width: 20rem;
}
.min-w-350 {
  min-width: 35rem;
}
.min-w-180 {
  min-width: 18rem;
}
.min-w-160 {
  min-width: 16rem;
}
.min-w-120 {
  min-width: 12rem;
}
.min-w-130 {
  min-width: 13rem !important;
}
.text-split {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media screen and (max-width: 800.98px) {
  .text-split {
    max-width: 100px;
  }
}

@media screen and (min-width: 992px) {
  .max-w-lg-50 {
    max-width: 50% !important;
  }
  .min-w-lg-350 {
    min-width: 35rem;
  }
}
@media screen and (max-width: 800.98px) {
  .d-m-block {
    display: block !important;
  }
}
@media screen and (max-width: 560px) {
  .min-w-sm-auto {
    min-width: auto !important;
  }
}
.py-12 {
  padding-block: 1.2rem !important;
}
.w-80 {
  width: 8rem !important;
}
.w-110 {
  width: 11rem !important;
}
.max-h-38 {
  max-height: 3.8rem;
}
.min-h-46 {
  min-height: 4.6rem;
}
.min-h-100 {
  min-height: 10rem;
}
.gap-10 {
  gap: 1rem;
}
.gap-15 {
  gap: 1.5rem;
}
.gap-20 {
  gap: 2rem;
}
.gap-30 {
  gap: 3rem;
}
.gap-50 {
  gap: 5rem;
}
.gap-5 {
  gap: 0.5rem !important;
}
.gap-5-0 {
  gap: 5px 0;
}
.flex-0 {
  flex: 0 !important;
}
.flex-50 > * {
  flex: 1;
}
.flex-6-1 > :first-child {
  flex: 6;
}
.flex-6-1 > :last-child {
  flex: 1;
}
.flex-w-auto {
  flex: auto !important;
  width: auto !important;
}
@media screen and (max-width: 991.98px) {
  .flex-sm-2-1 > :first-child {
    flex: 2;
  }
  .flex-sm-2-1 > :last-child {
    flex: 1;
  }
  .mobile-flex-col {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
}
.card-header.header-table {
  flex-wrap: wrap-reverse;
  gap: 2rem;
}
@media screen and (min-width: 768px) {
  .mobile-only {
    display: none !important;
  }
}
@media screen and (max-width: 767.98px) {
  .flex-wrap-md {
    flex-wrap: wrap;
  }
  .desktop-only {
    display: none !important;
  }
  .mobile-only {
    display: initial !important;
  }
}
@media screen and (max-width: 450px) {
  .card-header.header-table > * > * {
    width: 100%;
  }
}
.flex-1 {
  flex: 1 !important;
}
.flex-2-1 > :first-child {
  flex: 2;
}
.flex-4-1 > :first-child {
  flex: 4;
}
.flex-4-1 > :last-child,
.flex-2-1 > :last-child {
  flex: 1;
}
.rtl ngb-tooltip-window.tooltip {
  font-family: var(--ff-body-ar);
}
.ltr ngb-tooltip-window.tooltip {
  font-family: var(--ff-body-en);
}
ngb-tooltip-window.tooltip {
  font-size: 1.4rem;
}
ngb-tooltip-window.tooltip * {
  transition: none !important;
}
/* IFRAME */
iframe.iframe-container {
  width: 100%;
  height: calc(100vh - 320px);
  object-fit: contain;
  scrollbar-width: auto;
  scrollbar-color: var(--clr-light-blue-1) transparent;
}
iframe.iframe-container::-webkit-scrollbar {
  width: 0.5rem;
}
iframe.iframe-container::-webkit-scrollbar-track {
  background: transparent;
}
iframe.iframe-container::-webkit-scrollbar-thumb {
  background: var(--clr-light-blue-1);
  border-radius: 0.5rem;
}
img.iframe-container {
  width: 100%;
}
/*------------*/
/* Custom Modules Classes */
/* Section Title */
.section-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 2rem;
}
.section-title h3 {
  font-size: 1.6rem;
  font-weight: 700;
  color: var(--clr-body);
}
/* === ==== === */
/* Grid system */
.col-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
}
.col-grid.col-3 {
  grid-template-columns: repeat(auto-fit, minmax(calc(33% - 30px), 1fr));
  width: 100%;
}

/* *---* */
@media screen and (max-width: 767.98px) {
  .col-grid {
    grid-template-columns: 1fr !important;
  }
}
/* === ==== === */
/* Card Options */
.wd-card-option {
  border-radius: 0.5rem;
  background: var(--clr-gray-1);
}
.wd-card-option .card-title {
  background: var(--clr-secondary);
  padding: 0.7rem 2rem;
  border-radius: 0.5rem 0.5rem 0 0;
  margin-bottom: 0;
}
.wd-card-option .card-title h3 {
  font-size: 1.6rem;
  font-weight: 700;
  color: var(--clr-body);
  line-height: 2.6rem;
}
.wd-card-option .card-content {
  padding: 2rem;
}
.wd-card-option .card-content .radio {
  padding: 0;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.3rem;
  gap: 10px;
  cursor: pointer;
}
.wd-card-option .card-content .radio-group,
.wd-card-option .card-content .checkbox {
  margin: 0;
}
/* === ==== === */
/* NO Data */
.wd-nodata {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  padding: 3rem;
  border-radius: 0.5rem;
  background-color: var(--clr-gray-2);
  border: 1px solid var(--clr-secondary);
  margin-bottom: 1.5rem;
  height: 23rem;
}
.wd-nodata .nodata-content {
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--clr-body);
}
.wd-nodata .nodata-icon svg {
  width: 7.2rem;
}
/* === ==== === */
/* ratings */
ngb-rating {
  /* direction: ltr; */
  align-items: center;
  justify-content: center;
  width: 100%;
}
.rating-star {
  font-size: 40px;
  line-height: 1;
  color: var(--clr-secondary);
  display: inline-block;
  position: relative;
  margin: 0px 20px;
}
.filled,
.full {
  color: var(--clr-orange);
}
.rating-star > span {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  color: var(--clr-orange);
}
.rating-star > span + span {
  font-size: 16px;
  bottom: -30px;
  width: 60px;
  inset-inline-start: -15px;
  color: var(--clr-primary);
  font-weight: 500;
}
/* === ==== === */
/* Import Files | Page */
.wd-stepper {
  counter-reset: step;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wd-stepper .step {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  text-align: center;
}
.wd-stepper .step .step-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 9rem;
  height: 9rem;
  border: 2px dashed var(--clr-primary);
  border-radius: 50%;
}
.wd-stepper .step .step-icon:before {
  counter-increment: step;
  content: counter(step);
  position: absolute;
  inset-block-start: 0.2rem;
  inset-inline-start: -0.2rem;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  background-color: var(--clr-primary);
  color: var(--clr-white);
  font-size: 1.6rem;
  font-weight: 400;
}
.wd-stepper .step .step-content :last-child {
  margin-bottom: 0;
}
.wd-stepper .step .step-content {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.5rem;
  color: var(--clr-body);
}
.wd-stepper .step .step-content a {
  color: var(--clr-primary);
  text-decoration: underline;
}
@media screen and (max-width: 991px) {
  .wd-stepper {
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
  }
  .wd-stepper .step {
    flex: auto;
  }
}
/* === ==== === */
/* SPINNER */
.wd-spinner {
  position: fixed;
  width: 100vw;
  height: 100vh;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  transition: all 0.3s ease-in-out;
}
body:has(.wd-spinner) {
  overflow: hidden;
}
.wd-spinner > .overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  inset: 0;
  opacity: 1;
  /* background-color: var(--bg-body); */
  background-color: rgba(249, 252, 254, 0.33);
  backdrop-filter: blur(9px) brightness(90%);
}
.wd-spinner > img {
  width: 150px;
  z-index: 2001;
}
/* === ==== === */
.wd-btn-back {
  text-align: end;
  margin-top: -60px;
  margin-bottom: 20px;
}
[dir="rtl"] .wd-btn-back {
  float: left;
}
[dir="ltr"] .wd-btn-back,
html:not([dir="rtl"]) .wd-btn-back {
  float: right;
}

.tag-sa {
  position: absolute;
  top: 0;
  height: 48px;
  font-size: 16px;
  font-weight: 500;
  padding-inline: 20px;
  border-start-end-radius: 10px;
  border-end-end-radius: 10px;
  background-color: var(--clr-primary) !important;
  color: var(--clr-white) !important;
  line-height: 48px;
}

.right-0 {
  right: 0;
}

.left-0 {
  left: 0;
}

@media screen and (max-width: 1300px) {
  .stats-icon {
    width: 75px;
  }
}

@media screen and (max-width: 1200px) {
  .fs-xs {
    font-size: var(--fs-xs-smal) !important;
  }
  .fs-sm {
    font-size: var(--fs-sm-smal) !important;
  }
  .fs-md {
    font-size: var(--fs-md-smal) !important;
  }
  .fs-lg {
    font-size: var(--fs-lg-smal) !important;
  }
  .fs-xl {
    font-size: var(--fs-xl-smal) !important;
  }
  .fs-xxl {
    font-size: var(--fs-xxl-smal) !important;
  }

  .stat-fs-small {
    font-size: 14px !important;
  }

  .stats-icon {
    width: 55px;
  }
}

@media screen and (max-width: 767.98px) {
  .wd-btn-back {
    display: none;
  }

  .fs-xs {
    font-size: var(--fs-xs-smal) !important;
  }
  .fs-sm {
    font-size: var(--fs-sm-smal) !important;
  }
  .fs-md {
    font-size: var(--fs-md-smal) !important;
  }
  .fs-lg {
    font-size: var(--fs-lg-smal) !important;
  }
  .fs-xl {
    font-size: var(--fs-xl-smal) !important;
  }
  .fs-xxl {
    font-size: var(--fs-xxl-smal) !important;
  }
}
/* === ==== === */
[dir="rtl"] .reversed-mobile-direction {
  direction: ltr;
  text-align: end;
}
/* === ==== === */

input [type="txt"] {
  background-color: white !important; /* Change this to your desired color */
}

input [type="number"] {
  background-color: white !important; /* Change this to your desired color */
}

input [type="date"] {
  background-color: white !important; /* Change this to your desired color */
}

.form-check-input:checked {
  background-color: #006a61 !important; /* Change this to your desired color */
}

.disabled[readonly] {
  background-color: #f8f3e9 !important; /* Change this to your desired color */
}
