/* Scroll Top */
.scrolltop {
    position: fixed;
    opacity: 0;
    display: none;
    justify-content: center;
    align-items: center;
    width: 3.5rem;
    height: 3.5rem;
    bottom: 4rem;
    inset-inline-end: 2rem;
    cursor: pointer;
    z-index: 101;
    background-color: var(--clr-primary);
    box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075);
    transition: color 0.15s ease, background-color 0.15s ease,
        border-color 0.15s ease, box-shadow 0.15s ease;
    border-radius: 0.8rem !important;
}
.scrolltop .ng-fa-icon svg [fill] {
    transition: fill 0.3s ease;
    fill: var(--clr-white);
}
.scrolltop .ng-fa-icon svg:hover [fill] {
    transition: fill 0.3s ease;
}
.scrolltop:hover .ng-fa-icon svg [fill] {
    transition: fill 0.3s ease;
    fill: var(--clr-white);
}
.scrolltop:hover .ng-fa-icon svg:hover [fill] {
    transition: fill 0.3s ease;
}
[data-scrolltop="on"] .scrolltop {
    opacity: 0.3;
    -webkit-animation: animation-scrolltop 0.4s ease-out 1;
    animation: animation-scrolltop 0.4s ease-out 1;
    display: flex;
}
[data-scrolltop="on"] .scrolltop:hover {
    transition: color 0.15s ease, background-color 0.15s ease,
        border-color 0.15s ease, box-shadow 0.15s ease;
    opacity: 1;
}
@media (max-width: 991.98px) {
    .scrolltop {
        bottom: 3rem;
        inset-inline-end: 1.5rem;
        width: 3rem;
        height: 3rem;
    }
}
@-webkit-keyframes animation-scrolltop {
    0% {
        margin-bottom: -1.5rem;
    }

    to {
        margin-bottom: 0;
    }
}
@keyframes animation-scrolltop {
    0% {
        margin-bottom: -1.5rem;
    }

    to {
        margin-bottom: 0;
    }
}
