.wd-header-container {
  position: relative;
  padding: 0.5rem 0;

  &::before {
    content: "";
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 0.1rem;
    background: var(--clr-primary);
    background: gray;
    background: -webkit-linear-gradient(
      90deg,
      var(--clr-warning) 0%,
      var(--clr-primary) 100%
    );
    background: gray;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ceb06b",endColorstr="#006a62",GradientType=1);
  }
  &.container-border-top::before {
    bottom: auto;
    top: 0;
  }

  .wd-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5rem;
    padding-block: 1rem 1.7rem;

    .header-content {
      display: inline-flex;
      align-items: center;
      gap: 1.9rem;

      .wd-logo {
        width: 6rem;
        height: 3rem;
      }
      .header-nav {
        ul {
          display: flex;
          align-items: center;
          gap: 3.2rem;

          li {
            .nav-link {
              font-size: var(--fs-md);
              font-weight: 700;
              color: var(--clr-secondary);
              line-height: 2.2rem;

              &.active,
              &:hover {
                color: var(--clr-primary);
              }
            }
          }
        }
      }
    }
    .header-title {
      h2 {
        font-size: 3.2rem;
        font-weight: 700;
        line-height: 3.8rem;
      }
    }
    .header-toolbar {
      display: flex;
      align-items: center;
      gap: 1.5rem;

      .header-notification {
        position: relative;

        &.new-notification::before {
          content: "";
          position: absolute;
          width: 1rem;
          height: 1rem;
          background-color: var(--clr-red);
          border-radius: 50%;
          top: 2px;
          inset-inline-start: -1px;
        }
      }

      .header-userinfo {
        display: inline-flex;
        align-items: center;
        gap: 0.5rem;
        cursor: pointer;

        .user-img {
          img {
            width: 5.6rem;
            height: 5.6rem;
            border-radius: 50%;
            border: 1px solid rgba(0, 106, 98, 0.16);
          }
        }
      }
    }
  }
}

.wd-header-container-top {
  @extend .wd-header-container;

  &::before {
    top: 0px;
  }
}
