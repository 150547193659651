/* === === Variables === === */
label:not(.radio, .checkbox, .switch) {
  --form-label-fs: 16px;
  --form-label-fw: 500;
  --form-label-clr: var(--clr-body);
  // --form-label-mb: 10px;
}
.form-control {
  --form-control-width: 100%;
  --form-control-height: 40px;
  --form-control-lineheight: 40px;
  --form-control-padding-block: 5px;
  --form-control-padding-inline: 20px;
  --form-control-border-clr: var(--clr-secondary);
  --form-control-border-radius: 5px;
  --form-control-bg: var(--clr-white);
  --form-control-clr: var(--clr-dark);
  --form-control-fs: 14px;
  --form-control-fw: 400;
  /* focus */
  --form-control-focus-bg: var(--clr-white);
  --form-control-focus-border-clr: var(--clr-primary);
  /* placeholder */
  --form-control-placeholder-minheight: 18px;
  --form-control-placeholder-fs: 14px;
  --form-control-placeholder-fw: 400;
  --form-control-placeholder-clr: var(--clr-secondary);
  /* invalid */
  --form-control-invalid-border-clr: var(--clr-danger);
  /* disabled */
  --form-control-disabled-bg: var(--clr-secondary);
}
.ng-select {
  --form-control-padding-block: 5px;
  --ngselect-padding-ie: 20px;
  /* arrow icon */
  --ngselect-arrow-width: 25px;
  --ngselect-arrow-icon: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="14.116" height="8.071" viewBox="0 0 14.116 8.071"><path id="Icon_ionic-ios-arrow-down" data-name="Icon ionic-ios-arrow-down" d="M13.248,16.884l5.337-5.342a1,1,0,0,1,1.425,0,1.017,1.017,0,0,1,0,1.429l-6.048,6.052a1.007,1.007,0,0,1-1.391.029l-6.09-6.077a1.009,1.009,0,0,1,1.425-1.429Z" transform="translate(-6.188 -11.246)" fill="rgb(34,35,51)" /></svg>');
  --ngselect-arrow-icon-width: 25px;
  --ngselect-arrow-icon-height: 25px;
  /* clear icon */
  --ngselect-clear-padding: 10px;
  --ngselect-clear-width: 10px;
  --ngselect-clear-fs: 24px;
  /* Multiple */
  --ngselect-multi-lineheight: 25px;
  --ngselect-multi-gap: 5px;
  /* label */
  --ngselect-multi-label-clr: var(--clr-primary);
  --ngselect-multi-label-bg: var(--clr-light-blue);
  --ngselect-multi-label-border-radius: 5px;
  --ngselect-multi-label-padding-inline: 10px;
  /* label icon */
  --ngselect-multi-label-icon-padding-is: 5px;
  --ngselect-multi-label-icon-clr: var(--clr-dark);
  --ngselect-multi-label-icon-fs: 25px;
}
.ng-dropdown-panel {
  --ngselect-dropdown-padding: 15px 0;
  --ngselect-dropdown-fs: 20px;
  --ngselect-dropdown-clr: var(--clr-body);
  --ngselect-dropdown-bg: var(--clr-white);
  --ngselect-dropdown-border-clr: var(--clr-gray);
  --ngselect-dropdown-border-radius: 8px;
  --ngselect-dropdown-box-shadow: 5px 5px 15px rgb(111 129 145 / 10%);
  --ngselect-dropdown-maxheight: 160px;
  /* ng option */
  --ngselect-dropdown-option-gap: 7px;
  --ngselect-dropdown-option-padding: 0 15px;
  --ngselect-dropdown-option-height: 45px;
  --ngselect-dropdown-option-clr: var(--clr-body);
  --ngselect-dropdown-option-align: start;
  --ngselect-dropdown-option-hover-clr: var(--clr-white);
  --ngselect-dropdown-option-hover-bg: var(--clr-primary);
}
.checkbox {
  --checkbox-fs: 14px;
  --checkbox-fw: 400;
  --checkbox-margin: 10px 0;
  --checkbox-text-clr: var(--clr-dark);
  --checkbox-focus-border-clr: var(--clr-white);
  /* span */
  --checkbox-width: 21px;
  --checkbox-height: 21px;
  --checkbox-width-sm: 15px;
  --checkbox-height-sm: 15px;
  --checkbox-width-lg: 24px;
  --checkbox-height-lg: 24px;
  --checkbox-bg: var(--clr-white);
  --checkbox-border-clr: var(--clr-secondary);
  --checkbox-border-radius: 5px;
  --checkbox-border-radius-sm: 3px;
  --checkbox-border-radius-lg: 5px;
  /* check icon */
  --checkbox-check-width: 6px;
  --checkbox-check-height: 11px;
  --checkbox-check-width-sm: 4px;
  --checkbox-check-height-sm: 8px;
  --checkbox-check-width-lg: 8px;
  --checkbox-check-height-lg: 13px;
  --checkbox-check-inset-bs: 2px;
  --checkbox-check-inset-bs-sm: 1.5px;
  --checkbox-check-inset-bs-lg: 2px;
  --checkbox-check-border-width: 0 3px 3px 0 !important;
  --checkbox-check-border-width-sm: 0 2px 2px 0 !important;
  --checkbox-check-border-width-lg: 0 4px 4px 0 !important;
  --checkbox-check-clr: var(--clr-white);
  --checkbox-check-bg: transparent;
  --checkbox-check-transform: rotate(45deg);
  --checkbox-check-border-radius: 0px;
  --checkbox-check-margin: 0 auto;
  /* checked */
  --checkbox-active-bg: var(--clr-primary);
  --checkbox-active-border-clr: var(--clr-primary);
}
.checkbox-custom {
  --checkbox-custom-padding: 10px;
  --checkbox-custom-container-border-clr: var(--clr-gray-3);
  --checkbox-custom-border-clr: var(--clr-gray-3);
  --checkbox-custom-border-radius: 10px;
  --checkbox-custom-disabled-container-bg: rgb(223 236 248 / 40%);
  --checkbox-custom-disabled-bg: var(--clr-secondary-4);
}
.radio-group {
  --radiogroup-gap: 15px;
  --radiogroup-margin: 10px 0;
}
.radio {
  --radiogroup-radio-gap: 10px;
  --radiogroup-radio-clr: var(--clr-dark);
  --radiogroup-radio-fs: 14px;
  --radiogroup-radio-fw: 400;
  --radiogroup-radio-hover-clr: var(--clr-primary);
  --radiogroup-radio-width: 20px;
  --radiogroup-radio-height: 20px;
  --radiogroup-radio-bg: var(--clr-white);
  --radiogroup-radio-border-width: 1px;
  --radiogroup-radio-border-clr: var(--clr-secondary);
  --radiogroup-radio-border-radius: 50%;
  /* icon */
  --radiogroup-radio-icon-width: 15px;
  --radiogroup-radio-icon-height: 15px;
  --radiogroup-radio-icon-bg: var(--clr-white);
  --radiogroup-radio-icon-border-radius: 50%;
  /* active */
  --radiogroup-radio-active-bg: var(--clr-primary);
  --radiogroup-radio-active-border-clr: var(--clr-primary);
  --radiogroup-radio-icon-active-bg: transparent;
  --radiogroup-radio-icon-active-border-clr: var(--clr-primary);
}
.switch {
  --switch-width: 40px;
  --switch-height: 24px;
  --switch-margin: 2px 0;
  --switch-border-radius: 40vw;
  /* container */
  --switch-container-bg: var(--clr-gray-3);
  --switch-container-active-bg: var(--clr-primary);
  /* icon */
  --switch-icon-width: 16px;
  --switch-icon-height: 16px;
  --switch-icon-bg: var(--clr-white);
  --switch-icon-inset-block: 4px;
  --switch-icon-inset-ie: 4px;
  --switch-icon-boxshadow: 0 0.3rem 0.6rem var(--clr-gray-3);
  /* --switch-icon-active-inset-ie: 15px; */
  --switch-icon-active-boxshadow: 0 0.3rem 0.6rem var(--clr-primary);
}
/* === === Prefix === === */
.form-control.wd-form-control {
  --form-control-padding-block: 10px;
  --form-control-padding-inline: 25px;
  --form-control-height: 48px;
  --form-control-fs: 16px;
  --form-control-fw: 500;
  --form-control-clr: var(--clr-dark);
  --form-control-bg: var(--clr-white);
  --form-control-border-clr: var(--clr-gray);
  --form-control-border-radius: 10px;
  /* placeholder */
  --form-control-placeholder-minheight: 18px;
  --form-control-placeholder-fs: 16px;
  --form-control-placeholder-fw: 500;
  --form-control-placeholder-clr: var(--clr-secondary);
  /* disabled */
  // --form-control-disabled-bg: var(--clr-secondary-4);
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.form-group {
  position: relative;
  margin-bottom: 3rem;
  
  .color-clr-secondary {
    color: #7d8897;
  }
  .pass-eye {
    position: absolute;
    left: 24px;
    top: 7px;
  }
  > label:first-child {
    position: absolute;
    top: -25px;
    inset-inline-start: 1.5rem;
    right: 10px;
    z-index: 1;
  }
}

.disabled[readonly]{
background: #f3f3f3 !important;
color: gray !important;
}
.phone-input {
  gap: 1rem;

  > div.wd-form-control {
    max-width: 15rem;
    display: inline-flex;
    align-items: center;
    gap: 1rem;
    direction: ltr;
    color: var(--clr-secondary);
    cursor: auto;
  }
}
.input-icon {
  .form-control.wd-form-control {
    padding-inline-end: 45px;
  }
  .svg-icon {
    position: absolute;
    inset-block: 0;
    inset-inline-end: 2rem;
    width: 20px;
  }
}
.input-btn {
  .form-control.wd-form-control {
    padding-inline-end: 115px;
  }
  .btn.wd-btn {
    position: absolute;
    inset-inline-end: 0;
    height: 100%;
    border-radius: 10px 0px 0px 10px;
    min-width: 95px;

    .svg-icon svg {
      width: 27px;
      height: 27px;
    }
  }
}
.input-tag {
  display: inline-flex;
  align-items: center;
  .form-control.wd-form-control {
    border-start-end-radius: 0px;
    border-end-end-radius: 0px;
    border-end-start-radius: 10px;
    border-start-start-radius: 10px;
    &.form-control-sm ~ .tag {
      height: 35px;
    }
    &.form-control-lg ~ .tag {
      height: 60px;
    }
    &.form-control-xl ~ .tag {
      height: 72px;
    }
  }
  .tag {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    font-size: 16px;
    font-weight: 500;
    padding-inline: 20px;
    border-start-end-radius: 10px;
    border-end-end-radius: 10px;
    &.tag-primary {
      background-color: var(--clr-primary) !important;
      color: var(--clr-white) !important;
    }
    &.tag-light-primary-3 {
      background-color: var(--clr-light-primary-3) !important;
      color: var(--clr-primary) !important;
    }
  }
}
.form-control.wd-form-control {
  position: relative;
  ~ .validation {
    position: absolute;
    width: 2.5rem;
    height: 1.5rem;
    inset: 0;
    margin-block: auto;
    inset-inline-end: 1.5rem;
    inset-inline-start: auto;
  }
  ~ .validation-complete {
    @extend .validation;
    display: flex;
    width: fit-content;
    align-items: center;

    .success {
      color: var(--clr-primary);
      margin-left: 10px;
      font-weight: bold;
    }
  }
  &.form-control-sm {
    --form-control-padding-block: 5px;
    --form-control-padding-inline: 25px;
    --form-control-height: 35px;
    --form-control-fs: 16px;
    --form-control-fw: 400;
    --form-control-placeholder-minheight: 10px;
    --form-control-placeholder-fs: 16px;
    --form-control-placeholder-fw: 400;
  }
  &.form-control-lg {
    --form-control-padding-block: 10px;
    --form-control-padding-inline: 25px;
    --form-control-height: 60px;
    --form-control-fs: 20px;
    --form-control-fw: 500;
    --form-control-placeholder-minheight: 18px;
    --form-control-placeholder-fs: 20px;
    --form-control-placeholder-fw: 500;
  }
  &.form-control-xl {
    --form-control-padding-block: 10px;
    --form-control-padding-inline: 30px;
    --form-control-height: 72px;
    --form-control-fs: 20px;
    --form-control-fw: 500;
    --form-control-placeholder-minheight: 24px;
    --form-control-placeholder-fs: 20px;
    --form-control-placeholder-fw: 500;
  }
  &.ng-select {
    &.form-control-lg,
    &.form-control-xl {
      --ngselect-arrow-icon: url('data:image/svg+xml;utf8,<svg width="25" height="15" viewBox="0 0 25 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M23.7684 0.662046C23.3388 0.238009 22.7575 0 22.1516 0C21.5457 0 20.9645 0.238009 20.5348 0.662046L12.3016 8.72155L4.18303 0.662046C3.75334 0.238009 3.17208 0 2.5662 0C1.96032 0 1.37906 0.238009 0.949372 0.662046C0.734418 0.873694 0.563803 1.1255 0.447372 1.40294C0.33094 1.68037 0.270996 1.97795 0.270996 2.2785C0.270996 2.57905 0.33094 2.87663 0.447372 3.15406C0.563803 3.4315 0.734418 3.6833 0.949372 3.89495L10.6733 13.5481C10.8865 13.7615 11.1401 13.9309 11.4196 14.0465C11.6991 14.1621 11.9988 14.2216 12.3016 14.2216C12.6043 14.2216 12.9041 14.1621 13.1836 14.0465C13.463 13.9309 13.7167 13.7615 13.9299 13.5481L23.7684 3.89495C23.9834 3.6833 24.154 3.4315 24.2704 3.15406C24.3869 2.87663 24.4468 2.57905 24.4468 2.2785C24.4468 1.97795 24.3869 1.68037 24.2704 1.40294C24.154 1.1255 23.9834 0.873694 23.7684 0.662046Z" fill="rgb(206, 206, 206)"/></svg>');
      .ng-select-container .ng-clear-wrapper .ng-clear {
        padding-top: var(--ngselect-clear-padding);
      }
    }

    &.ngselect-light-primary {
      --form-control-clr: var(--clr-primary);
      --form-control-placeholder-clr: var(--clr-primary);
      --form-control-bg: var(--clr-light-primary-2);
      --form-control-border-clr: transparent;
      --ngselect-arrow-icon: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="14.116" height="8.071" viewBox="0 0 14.116 8.071"><path id="Icon_ionic-ios-arrow-down" data-name="Icon ionic-ios-arrow-down" d="M13.248,16.884l5.337-5.342a1,1,0,0,1,1.425,0,1.017,1.017,0,0,1,0,1.429l-6.048,6.052a1.007,1.007,0,0,1-1.391.029l-6.09-6.077a1.009,1.009,0,0,1,1.425-1.429Z" transform="translate(-6.188 -11.246)" fill="rgb(0, 106, 98)" /></svg>');
    }
  }
  &.ngselect-birth {
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    gap: 1rem;
    > .ng-select {
      --ngselect-arrow-icon: url('data:image/svg+xml;utf8,<svg width="25" height="15" viewBox="0 0 25 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M23.7684 0.662046C23.3388 0.238009 22.7575 0 22.1516 0C21.5457 0 20.9645 0.238009 20.5348 0.662046L12.3016 8.72155L4.18303 0.662046C3.75334 0.238009 3.17208 0 2.5662 0C1.96032 0 1.37906 0.238009 0.949372 0.662046C0.734418 0.873694 0.563803 1.1255 0.447372 1.40294C0.33094 1.68037 0.270996 1.97795 0.270996 2.2785C0.270996 2.57905 0.33094 2.87663 0.447372 3.15406C0.563803 3.4315 0.734418 3.6833 0.949372 3.89495L10.6733 13.5481C10.8865 13.7615 11.1401 13.9309 11.4196 14.0465C11.6991 14.1621 11.9988 14.2216 12.3016 14.2216C12.6043 14.2216 12.9041 14.1621 13.1836 14.0465C13.463 13.9309 13.7167 13.7615 13.9299 13.5481L23.7684 3.89495C23.9834 3.6833 24.154 3.4315 24.2704 3.15406C24.3869 2.87663 24.4468 2.57905 24.4468 2.2785C24.4468 1.97795 24.3869 1.68037 24.2704 1.40294C24.154 1.1255 23.9834 0.873694 23.7684 0.662046Z" fill="rgb(206, 206, 206)"/></svg>');
      --ngselect-value-align: center;

      border: 0;
      text-align: center;
      border-inline-end: 1px solid var(--clr-gray);
      border-radius: 0;

      &:last-child {
        border-inline-end: 0px;
      }
    }
  }
  &.ngselect-filter {
    --ngselect-arrow-icon: url('data:image/svg+xml;utf8,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22 3H2L10 12.46V19L14 21V12.46L22 3Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  }
}
.checkbox.wd-checkbox {
  --checkbox-width: 28px;
  --checkbox-height: 28px;
  --checkbox-fs: 16px;
  --checkbox-fw: 500;
  --checkbox-border-clr: var(--clr-gray);
  --checkbox-border-radius: 8px;
  --checkbox-active-bg: var(--clr-white);
  --checkbox-active-border-clr: var(--clr-primary);
  --checkbox-focus-border-clr: var(--clr-primary);
  // check
  --checkbox-check-width: calc(100% - 4px);
  --checkbox-check-height: calc(100% - 4px);
  --checkbox-check-bg: var(--clr-primary);
  --checkbox-check-border-width: 0 !important;
  --checkbox-check-transform: none;
  --checkbox-check-border-radius: calc(var(--checkbox-border-radius) - 2px);
  --checkbox-check-margin: auto;
  --checkbox-check-inset-bs: 0px;
  // sm
  --checkbox-width-sm: 24px;
  --checkbox-height-sm: 24px;
  --checkbox-check-width-sm: calc(100% - 2px);
  --checkbox-check-height-sm: calc(100% - 2px);
  --checkbox-check-border-width-sm: 0 !important;
  --checkbox-border-radius-sm: 6px;
  --checkbox-check-inset-bs-sm: 0px;
  --checkbox-check-border-radius: calc(var(--checkbox-border-radius) - 3px);

  // lg
  --checkbox-width-lg: 32px;
  --checkbox-height-lg: 32px;
  --checkbox-check-width-lg: calc(100% - 4px);
  --checkbox-check-height-lg: calc(100% - 4px);
  --checkbox-fs-lg: 20px;
  --checkbox-check-border-width-lg: 0 !important;
  --checkbox-border-radius-lg: 8px;
  --checkbox-check-inset-bs-lg: 0px;

  &.checkbox-gold {
    --checkbox-active-border-clr: var(--clr-warning);
    --checkbox-check-bg: var(--clr-warning);
    --checkbox-focus-border-clr: var(--clr-warning);
  }
}

.radio.wd-radio {
  --radiogroup-radio-width: 28px;
  --radiogroup-radio-height: 28px;
  --radiogroup-radio-border-width: 1px;
  --radiogroup-radio-border-clr: var(--clr-gray);
  --radiogroup-radio-border-radius: 8px;

  /* icon */
  --radiogroup-radio-icon-width: 100%;
  --radiogroup-radio-icon-height: 100%;
  --radiogroup-radio-icon-border-radius: var(--radiogroup-radio-border-radius);
  --radiogroup-radio-icon-border-clr: var(--radiogroup-radio-border-clr);

  --radiogroup-radio-active-bg: var(--clr-warning);
  --radiogroup-radio-active-border-clr: var(--clr-warning);
  --radiogroup-radio-icon-active-bg: var(--clr-warning);
  --radiogroup-radio-icon-active-border-clr: var(--clr-warning);

  &.radio-primary {
    --radiogroup-radio-active-bg: var(--clr-primary);
    --radiogroup-radio-active-border-clr: var(--clr-primary);
    --radiogroup-radio-icon-active-bg: var(--clr-primary);
    --radiogroup-radio-icon-active-border-clr: var(--clr-warning);
  }
  &.radio-toggle {
    --radiogroup-radio-icon-width: calc(100% - 4px);
    --radiogroup-radio-icon-height: calc(100% - 4px);
    --radiogroup-radio-icon-border-radius: calc(
      var(--radiogroup-radio-border-radius) - 2px
    );
    --radiogroup-radio-active-bg: transparent;
    // --radiogroup-radio-active-border-clr: var(--clr-warning);
    // --radiogroup-radio-icon-active-bg: var(--clr-warning);
    // --radiogroup-radio-icon-active-border-clr: var(--clr-warning);
  }
}
/* ==== === ==== === ==== === ==== */
/* === === General Styling === === */
label {
  display: block;
  font-size: var(--form-label-fs);
  font-weight: var(--form-label-fw);
  color: var(--form-label-clr);
  margin-bottom: var(--form-label-mb);
}
.form-control {
  display: inline-block;
  width: var(--form-control-width);
  height: var(--form-control-height);
  line-height: var(--form-control-lineheight);
  padding: var(--form-control-padding-block) var(--form-control-padding-inline);
  border: 1px solid var(--form-control-border-clr);
  border-radius: var(--form-control-border-radius);
  background: var(--form-control-bg);
  color: var(--form-control-clr);
  font-size: var(--form-control-fs);
  font-weight: var(--form-control-fw);
  cursor: pointer;
}
.form-control:focus,
.form-control[class*="-focused"],
.form-control:focus-visible {
  outline: 0;
  box-shadow: none;
  background: var(--form-control-focus-bg);
  border-color: var(--form-control-focus-border-clr);
}
.form-control::placeholder,
.form-control[class*="-placeholder"] {
  min-height: var(--form-control-placeholder-minheight);
  font-size: var(--form-control-placeholder-fs);
  font-weight: var(--form-control-placeholder-fw);
  color: var(--form-control-placeholder-clr);
}
.form-control[readonly],
.form-control:disabled,
.form-control:disabled *,
.form-control[class*="-disabled"] {
  background: #fff;
  cursor: not-allowed;
}
.form-control.wd-date[readonly] {
  cursor: pointer !important;
}
.form-control.ng-dirty.ng-invalid {
  border-color: var(--clr-danger) !important;
}
@media screen and (max-width: 560px) {
  input.form-control {
    min-width: 15rem;
  }
}
/* ==== === ==== === ==== === ==== */
/* textarea */
textarea.form-control {
  height: auto;
  line-height: 1.5;
  min-height: var(--form-control-height);
}
/* ==== === ==== === ==== === ==== */
/* ng-select */
.ng-select {
  padding-inline-end: var(--ngselect-padding-ie);
}
.ng-select .ng-select-container .ng-input {
  padding-inline-end: var(--ngselect-arrow-width);
}
.ng-select .ng-select-container .ng-input,
.ng-select.form-control
  .ng-select-container
  .ng-value-container
  .ng-input
  > input,
.ng-select.form-control {
  cursor: pointer;
}
.ng-select .ng-select-container {
  height: 100%;
}
.ng-select .ng-arrow-wrapper {
  width: var(--ngselect-arrow-width);
  height: 100%;
}
.ng-select .ng-arrow-wrapper:after {
  content: "";
  width: var(--ngselect-arrow-icon-width);
  height: var(--ngselect-arrow-icon-height);
  position: absolute;
  background-image: var(--ngselect-arrow-icon);
  background-repeat: no-repeat;
  background-position: center;
  inset: 0;
  margin-block: auto;
}
.ng-select .ng-select-container .ng-arrow-wrapper.ng-has-value,
.ng-select .ng-select-container.ng-has-value .ng-placeholder,
.ng-select .ng-select-container .ng-arrow-wrapper .ng-arrow,
.ng-select.ng-select-disabled .ng-arrow-wrapper {
  display: none;
}
.ng-select .ng-select-container .ng-placeholder {
  transition: color 400ms 133.3333333333ms cubic-bezier(0.25, 0.8, 0.25, 1);
  font-size: var(--form-control-placeholder-fs);
  font-weight: var(--form-control-placeholder-fw);
  color: var(--form-control-placeholder-clr);
}
.ng-select .ng-select-container .ng-clear-wrapper {
  display: inline-flex;
  padding-inline-end: calc(
    var(--ngselect-clear-width) + var(--ngselect-clear-padding)
  );
  width: var(--ngselect-clear-width);
  font-size: var(--ngselect-clear-fs);
}
.ng-select .ng-select-container .ng-clear-wrapper .ng-clear {
  font-size: inherit;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container,
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  align-items: center;
  justify-content: var(--ngselect-value-align);
}
/* --- ng select multiple --- */
.ng-select.form-control.ng-select-multiple {
  height: auto;
  min-height: var(--form-control-height);
  line-height: var(--ngselect-multi-lineheight);
}
.ng-select.form-control.ng-select-multiple .ng-arrow-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
}
.ng-select.ng-select-multiple .ng-clear-wrapper .ng-clear {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ng-select.form-control.ng-select-multiple .ng-value-container {
  display: inline-flex;
  align-items: center;
  gap: var(--ngselect-multi-gap);
  min-height: calc(
    var(--form-control-height) - (var(--form-control-padding-block) * 2) - 2px
  );
}
.ng-select.form-control.ng-select-multiple .ng-value-container .ng-value {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  border-radius: var(--ngselect-multi-label-border-radius);
  padding-inline: var(--ngselect-multi-label-padding-inline);
  background-color: var(--ngselect-multi-label-bg);
}
.ng-select.form-control.ng-select-multiple
  .ng-value-container
  .ng-value
  .ng-value-icon {
  order: 2;
  margin-top: -5px;
  padding-inline-start: var(--ngselect-multi-label-icon-padding-is);
  color: var(--ngselect-multi-label-icon-clr);
  font-size: var(--ngselect-multi-label-icon-fs);
}
.ng-select.form-control.ng-select-multiple
  .ng-value-container
  .ng-value
  .ng-value-label {
  order: 1;
  color: var(--ngselect-multi-label-clr);
}
@media screen and (max-width: 767.98px) {
  .ng-select.form-control.ng-select-multiple {
    padding: 1rem;
  }
}
/* --- ng select dropdown --- */
body > .ng-dropdown-panel,
.ng-select .ng-dropdown-panel {
  top: auto;
  left: 0;
  min-width: 100%;
  padding: var(--ngselect-dropdown-padding);
  margin-top: 1rem;
  text-align: center;
  font-size: var(--ngselect-dropdown-fs);
  line-height: 1;
  color: var(--ngselect-dropdown-clr);
  background: var(--ngselect-dropdown-bg);
  border: 1px solid var(--ngselect-dropdown-border-clr);
  border-radius: var(--ngselect-dropdown-border-radius);
  box-shadow: var(--ngselect-dropdown-box-shadow);
  outline: 0;
  z-index: 1047 !important;
  height: auto;
}
body > .ng-dropdown-panel .ng-dropdown-panel-items,
.ng-select .ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: var(--ngselect-dropdown-maxheight);
}
body > .ng-dropdown-panel .ng-dropdown-panel-items .ng-option,
.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  height: var(--ngselect-dropdown-option-height);
  padding: var(--ngselect-dropdown-option-padding);
  color: var(--ngselect-dropdown-option-clr);
  line-height: var(--ngselect-dropdown-option-height);
  text-align: var(--ngselect-dropdown-option-align);
  font-size: inherit;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
body > .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked,
.ng-select
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-marked {
  background: var(--ngselect-dropdown-option-hover-bg);
  color: var(--ngselect-dropdown-option-hover-clr);
}
body > .ng-dropdown-panel .ng-dropdown-panel-items .ng-option text,
.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option text {
  display: inline-block;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
body > .ng-dropdown-panel.ng-select {
  height: auto;
  z-index: 1060 !important;
  font-size: var(--ngselect-dropdown-fs);
  padding: var(--ngselect-dropdown-padding);
  border-radius: var(--ngselect-dropdown-border-radius);
  margin-top: 0.8rem;
  border-color: transparent;
}
.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  gap: var(--ngselect-dropdown-option-gap);
}
/* === ==== === */
.ng-select.form-control {
  min-width: 15rem;
}
/* ==== === ==== === ==== === ==== */
/* CHECKBOX */
.checkbox {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  text-align: start;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: var(--checkbox-fs);
  font-weight: var(--checkbox-fw);
  margin: var(--checkbox-margin);
  color: var(--checkbox-text-clr);
}
.checkbox > input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.checkbox > span {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: var(--checkbox-width);
  height: var(--checkbox-height);
  background-color: var(--checkbox-bg);
  border: 1px solid var(--checkbox-border-clr);
  border-radius: var(--checkbox-border-radius);
  transition: all 0.3s ease;
}
.checkbox > span:after {
  content: "";
  position: absolute;
  inset: 0;
  margin: var(--checkbox-check-margin);
  inset-block-start: var(--checkbox-check-inset-bs);
  width: var(--checkbox-check-width);
  height: var(--checkbox-check-height);
  border-color: transparent;
  border-style: solid;
  border-width: var(--checkbox-check-border-width);
  transform: var(--checkbox-check-transform);
  border-radius: var(--checkbox-check-border-radius);
}
.checkbox > input:focus ~ span {
  border-color: var(--checkbox-focus-border-clr);
}
.checkbox > input:checked ~ span {
  background-color: var(--checkbox-active-bg);
  transition: all 0.3s ease;
  border-color: var(--checkbox-active-border-clr);
}
.checkbox > input:checked ~ span:after {
  display: block;
  border-color: var(--checkbox-check-clr);
  background-color: var(--checkbox-check-bg);
}
/* CHECKBOX SM */
.checkbox.checkbox-sm > span {
  width: var(--checkbox-width-sm);
  height: var(--checkbox-height-sm);
  border-radius: var(--checkbox-border-radius-sm);
}
.checkbox.checkbox-sm > span::after {
  width: var(--checkbox-check-width-sm);
  height: var(--checkbox-check-height-sm);
  inset-block-start: var(--checkbox-check-inset-bs-sm);
  border-width: var(--checkbox-check-border-width-sm);
}
/* CHECKBOX SM */
.checkbox.checkbox-lg > span {
  width: var(--checkbox-width-lg);
  height: var(--checkbox-height-lg);
  border-radius: var(--checkbox-border-radius-lg);
  font-size: var(--checkbox-fs-lg);
}
.checkbox.checkbox-lg > span::after {
  width: var(--checkbox-check-width-lg);
  height: var(--checkbox-check-height-lg);
  inset-block-start: var(--checkbox-check-inset-bs-lg);
  border-width: var(--checkbox-check-border-width-lg);
}
/* CHECKBOX || CUSTOM */
.checkbox.checkbox-custom {
  width: 100%;
  padding: var(--checkbox-custom-padding);
  border: 1px solid var(--checkbox-custom-container-border-clr);
  border-radius: var(--checkbox-custom-border-radius);
}
.checkbox.checkbox-custom > span {
  border-color: var(--checkbox-custom-border-clr);
}
.checkbox.checkbox-custom.disabled > input:not(:checked) ~ span {
  background: var(--checkbox-custom-disabled-bg);
}
.checkbox.checkbox-custom.disabled {
  background: var(--checkbox-custom-disabled-container-bg);
  pointer-events: none;
  cursor: not-allowed;
}
/* ==== === ==== === ==== === ==== */
/* RADIO BUTTONS */
.radio-group {
  display: inline-flex;
  align-items: center;
  gap: var(--radiogroup-gap);
  margin: var(--radiogroup-margin);
}
.radio-group.vertical-group {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.radio.btn,
.radio.btn.active,
.radio.btn:hover {
  background: 0;
  border: 0;
  height: auto;
}
.radio {
  display: flex;
  align-items: center;
  gap: var(--radiogroup-radio-gap);
  padding: 0;
  margin-bottom: 0;
  color: var(--radiogroup-radio-clr);
  font-size: var(--radiogroup-radio-fs);
  font-weight: var(--radiogroup-radio-fw);
  cursor: pointer;
}
.radio:hover {
  color: var(--radiogroup-radio-hover-clr);
}
.radio input[type="radio"] {
  border-radius: 5px;
  position: absolute;
  visibility: hidden;
  z-index: -1;
}
.radio span {
  position: relative;
  display: inline-block;
  width: var(--radiogroup-radio-width);
  height: var(--radiogroup-radio-height);
  border: var(--radiogroup-radio-border-width) solid
    var(--radiogroup-radio-border-clr);
  border-radius: var(--radiogroup-radio-border-radius);
  background: var(--radiogroup-radio-bg);
}
.radio span::before {
  content: "";
  position: absolute;
  inset: 0;
  margin: auto;
  width: var(--radiogroup-radio-icon-width);
  height: var(--radiogroup-radio-icon-height);
  background: var(--radiogroup-radio-icon-bg);
  border-radius: var(--radiogroup-radio-icon-border-radius);
}
.radio.active span,
.radio > input:checked ~ span {
  border-color: var(--radiogroup-radio-active-border-clr);
  background: var(--radiogroup-radio-active-bg);
}
.radio.active span::before,
.radio > input:checked ~ span::before {
  background: var(--radiogroup-radio-icon-active-bg);
}
/* ==== === ==== === ==== === ==== */
/* SWITCH */
.switch {
  display: inline-flex;
  align-items: center;
  margin: 0;
  cursor: pointer;
}
.switch input:empty {
  margin-left: -999rem;
  width: 0;
  height: 0;
  overflow: hidden;
  position: absolute;
  opacity: 0;
}
.switch input:empty ~ span {
  display: inline-block;
  position: relative;
  width: var(--switch-width);
  height: var(--switch-height);
  margin: var(--switch-margin);
  border-radius: var(--switch-border-radius);
  text-indent: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* cursor: pointer; */
}
.switch input:empty ~ span:before,
.switch input:empty ~ span:after {
  content: " ";
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  inset-block: 0;
  inset-inline-end: 0;
  transition: all 100ms ease-in;
  border-radius: var(--switch-border-radius);
}
.switch input:empty ~ span:before {
  width: var(--switch-width);
  background-color: var(--switch-container-bg);
}
.switch input:checked ~ span:before {
  background-color: var(--switch-container-active-bg);
}
.switch input:empty ~ span:after {
  width: var(--switch-icon-width);
  height: var(--switch-icon-height);
  inset-block: var(--switch-icon-inset-block);
  inset-inline-end: var(--switch-icon-inset-ie);
  background-color: var(--switch-icon-bg);
  box-shadow: var(--switch-icon-boxshadow);
}
.switch input:checked ~ span:after {
  inset-inline-end: calc(
    var(--switch-width) - var(--switch-icon-width) - var(--switch-icon-inset-ie)
  );
  /* inset-inline-end: var(--switch-icon-active-inset-ie); */
  box-shadow: var(--switch-icon-active-boxshadow);
}
/* ==== === ==== === ==== === ==== */

/* === ==== === */
/* ==== === ==== === ==== === ==== */
/* === ==== === */
/* OLD || Input Group */
/* === ==== === */
.input-group {
  display: inline-flex;
  align-items: center;
  width: auto;
}

.input-icon-group,
.input-btn-group {
  position: relative;
}

.input-btn-group.input-calendar > .btn {
  position: absolute;
  width: calc(4.5rem - 0.1rem);
  height: calc(100% - 0.2rem);
  inset-block-start: 0.1rem;
  inset-inline-start: 0.1rem;
  border-inline-end: 0.1rem solid var(--clr-secondary);
  border-radius: 0;
  border-start-start-radius: 0.5rem;
  border-end-start-radius: 0.5rem;
  color: var(--clr-gray-3);
}

.input-btn-group.input-calendar > .btn:hover {
  background-color: var(--clr-light-blue);
  color: var(--clr-primary);
}

.input-btn-group.input-calendar > input.form-control {
  padding-inline-start: 6.5rem;
}

.input-btn-group.input-calendar > input.form-control:disabled ~ .btn.wd-btn {
  border-color: var(--clr-gray-3);
  cursor: default;
  pointer-events: none;
}

.input-btn-group.input-copy > .btn {
  position: absolute;
  width: calc(4.5rem - 0.1rem);
  height: calc(100% - 0.2rem);
  max-height: 3.8rem;
  inset-block-start: 0.1rem;
  border-radius: 0;
  border-start-end-radius: 0.5rem;
  border-end-end-radius: 0.5rem;
  inset-inline-end: 0.1rem;
  border-inline-start: 0.1rem solid var(--clr-light-blue);
  color: var(--clr-primary);
  background-color: var(--clr-light-blue);
}

.input-btn-group.input-pay > .btn:hover,
.input-btn-group.input-copy > .btn:hover {
  background-color: var(--clr-light-blue-1);
}

.input-btn-group.input-pay > input.form-control,
.input-btn-group.input-copy > input.form-control {
  padding-inline-end: 6.5rem;
}

.input-btn-group.input-pay > input.form-control:disabled ~ .btn.wd-btn {
  display: none;
}

.input-btn-group.input-copy > input.form-control:disabled ~ .btn.wd-btn {
  border-color: var(--clr-gray-3);
  cursor: default;
  pointer-events: none;
}

/* --- --- */
.input-btn-group.input-pay > .btn {
  position: absolute;
  inset-block-start: 0.6rem;
  inset-inline-end: 0.6rem;
  border-radius: 0.5rem;
}

/* --- --- */
.input-btn-group.input-search > input {
  padding-inline-end: 4rem;
}

.input-btn-group.input-search > span {
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: calc(100% - 0.2rem);
  inset-block-start: 0.1rem;
  padding-inline: 1rem;
  color: var(--clr-gray);
  font-size: 1.4rem;
  font-weight: bold;
  border-radius: 0;
  border-start-end-radius: 0.5rem;
  border-end-end-radius: 0.5rem;
  inset-inline-end: 0.1rem;
}

/* --- --- */
.input-icon-group > input.form-control {
  padding-inline-end: 6rem;
}

.input-icon-group > .input-icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0.1rem;
  inset-inline-end: 0.1rem;
  width: calc(5rem - 0.1rem);
  height: calc(4rem - 0.2rem);
  border-radius: 0;
  border-start-end-radius: 0.5rem;
  border-end-end-radius: 0.5rem;
  background: var(--clr-light-blue);
  color: var(--clr-white);
}

.input-icon-group > .input-icon > i {
  width: 2.5rem;
  height: 2.5rem;
  object-fit: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.input-icon-group.input-barcode i.scan-icon {
  background-image: url(/assets/media/icons/scan.svg);
}

/* --- --- */
.input-btn-group.input-payment > input {
  padding-inline-end: 6rem;
}

.input-btn-group.input-payment > .wd-btn {
  position: absolute;
  inset-block: 0;
  inset-inline-end: 0.6rem;
}

.input-btn-group.input-payment > .wd-btn .svg-icon {
  width: 3rem;
  height: 3rem;
  padding: 0.5rem;
  background: var(--clr-light-blue);
  border-radius: 0.5rem;
}

/* === ==== === */
/* Custom Inputs  */
/* Input | Discount */
.inputs-group.input-discount > * {
  max-width: 6rem;
  min-width: 6rem;
  padding: 0.5rem !important;
}

.inputs-group.input-discount > ng-select {
  border-radius: 0 0.5rem 0.5rem 0;
  border-inline-end: 0px;
  height: 3.5rem;
}

.inputs-group.input-discount > input {
  border-radius: 0.5rem 0rem 0rem 0.5rem;
  border-inline-start: 0px;
  height: 3.5rem;
}

.inputs-group.input-discount .ng-select .ng-arrow-wrapper {
  width: 1.5rem;
}

.inputs-group.input-discount .ng-select .ng-arrow-wrapper:after {
  width: 1.1rem;
  height: 1.1rem;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="8.485" height="8.485" viewBox="0 0 8.485 8.485"><path id="Path_9752" data-name="Path 9752" d="M57,13.187h6v6" transform="translate(58.115 -26.738) rotate(135)" fill="rgb(135, 153, 168)"/></svg>');
}

.inputs-group.input-discount .ng-select.form-control:focus,
.inputs-group.input-discount .ng-select.form-control.ng-select-focused,
.inputs-group.input-discount .ng-select.form-control.ng-select-focused ~ input {
  background-color: var(--clr-gray-1);
  border-color: var(--clr-primary);
}

/* Input | Amount + -  */
.input-group.input-amount .btn.btn-icon {
  width: 2rem;
  height: 2rem;
  border-radius: 0.5rem !important;
  font-size: 1.2rem;
  border-color: var(--clr-light-blue-2);
  background-color: var(--clr-light-blue);
  color: var(--clr-primary);
  line-height: 1;
  margin: 0;
}

.table.wd-table .input-group.input-amount {
  gap: 1rem;
}

.input-group.input-amount input {
  text-align: center;
  min-width: 10rem;
  border-radius: 0.5rem !important;
  flex: 0;
  margin-inline: 1.5rem !important;
}

.table.wd-table .input-group.input-amount > input {
  min-width: 7rem;
  padding: 1rem;
  margin: 0 !important;
}

.table.wd-table.editable-table .ng-select {
  background-color: var(--clr-white) !important;
}

/* Remove arrows inside input type number */
/* Chrome, Safari, Edge, Opera */
.no-counter-arrow::-webkit-outer-spin-button,
.no-counter-arrow::-webkit-inner-spin-button,
.inputs-group.phone-select input::-webkit-outer-spin-button,
.inputs-group.phone-select input::-webkit-inner-spin-button,
.input-group.input-amount input::-webkit-outer-spin-button,
.input-group.input-amount input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.no-counter-arrow,
.inputs-group.phone-select input[type="number"],
.input-group.input-amount input[type="number"] {
  -moz-appearance: textfield;
}

/* === ==== === */
/* Inputs | File Upload */
/* --- --- */
input.upload-file {
  display: none;
  position: relative;
  text-indent: -99999rem;
  cursor: pointer;
}

input.upload-file::-webkit-file-upload-button,
input.upload-file::file-selector-button {
  display: none;
  visibility: hidden;
}

/* --- --- */
.wd-upload-file {
  display: flex;
  align-items: flex-start;
  gap: 2.5rem;
  margin-block: 4rem;
}

.wd-upload-file .uploaded-file {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.wd-upload-file .uploaded-file h4 {
  font-size: 1.6rem;
  font-weight: 700;
  color: var(--clr-body);
  line-height: 2.5rem;
}

.wd-upload-file .uploaded-file .upload-status {
  font-size: 1.6rem;
  font-weight: 400;
  color: var(--clr-gray);
  line-height: 2.3rem;
}

/* .upload-progressbar {width: 100%; height: 0.5rem; background: var(--clr-secondary);} */
.wd-upload-file .uploaded-file .progress {
  width: 100%;
  border-radius: 0;
}

/* @keyframes load {0% { width: 0; } 100% { width: 68%; }} */
.upload-actions {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.upload-actions.disabled {
  opacity: 0.2;
  pointer-events: none;
}

.upload-actions > * {
  flex: 1;
}

/* --- --- */
// Upload-files
.wd-upload-file-input {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
}
.wd-upload-file-input > input {
  text-indent: -99999rem;
  display: flex;
  padding: 2rem;
  min-height: 12rem;
  text-align: center;
  background: var(--clr-white);
  border-radius: 1rem;
  border: 1px dashed var(--clr-gray);
}
.wd-upload-file-input > input:focus {
  background: var(--clr-gray-2);
  border-color: var(--clr-primary);
}
.wd-upload-file-input .upload-file-content {
  position: absolute;
  text-align: center;
  font-size: var(--fs-md);
  font-weight: 400;
  line-height: 2.8rem;
  color: var(--clr-secondary);
  pointer-events: none;
}
.wd-upload-file-input .upload-file-content > .wd-btn {
  margin-block: 2rem;
}
.wd-upload-file-input .upload-file-content > span:last-child {
  display: block;
  font-size: 1.4rem;
  color: var(--clr-gray);
}
.wd-upload-file-input.upload-btn > input {
  position: absolute;
  min-height: 0;
  border: 0;
  height: 40px;
  border-radius: 0;
  background: transparent;
  padding: 0;
  inset-inline-end: 0;
}

/* === ==== === */
/* FIXING CALENDAR  */
ngb-datepicker.dropdown-menu {
  font-size: 100%;
  direction: ltr;
  width: 60%;

  .ngb-dp-months {
    display: block;
  }
  .ngb-dp-month .ngb-dp-week {
    width: 100%;
  }
  .ngb-dp-day,
  .ngb-dp-weekday,
  .ngb-dp-week-number {
    width: 100%;
    justify-content: center;
    height: 2.5rem;
    display: flex;
    align-items: center;
    color: #006a62;
  }
  [ngbDatepickerDayView] {
    width: 100%;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ngb-dp-navigation-select {
    flex: 1 1 70%;
  }
  ngb-datepicker-navigation-select > .form-select {
    height: 3rem;
    font-size: 15px;
  }
  .ngb-dp-arrow {
    width: 3rem;
    height: 3rem;
  }
  .ngb-dp-navigation-chevron {
    width: 0.9em;
    height: 0.9em;
  }
}
/* === ==== === */
.inputs-group {
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.inputs-group.phone-select {
  gap: 1rem;
}

.inputs-group.phone-select > .ng-select {
  min-width: 10.5rem;
  max-width: 12rem;
  padding: 0rem;
}

.inputs-group.phone-select > .ng-select .ng-dropdown-panel {
  margin-top: 1rem;
}

.inputs-group.phone-select > .ng-select .ng-select-container {
  padding-inline-end: 1rem;
}

.inputs-group.phone-select > .ng-select .ng-value-container {
  padding: 1rem;
  padding-inline-end: 0.5rem;
}

.inputs-group.phone-select > .ng-select .ng-value-container .ng-value {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.inputs-group.phone-select
  > .ng-select
  .ng-value-container
  .ng-value
  > span:last-child,
.inputs-group.phone-select
  > .ng-select
  .ng-dropdown-panel
  .ng-option
  > span:last-child {
  direction: ltr;
}

.inputs-group.phone-select > .ng-select .ng-value-container .ng-value svg {
  width: 3.1rem;
}

.inputs-group.phone-select > .ng-select .ng-arrow-wrapper {
  width: 1rem;
}

.inputs-group.phone-select > .ng-select .ng-arrow-wrapper:after {
  width: 1rem;
  height: 1rem;
}

@media screen and (max-width: 500.98px) {
  .inputs-group.phone-select {
    flex-direction: column;
  }

  .inputs-group.phone-select > .ng-select {
    min-width: 100%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .inputs-group.phone-select > input {
    padding-inline: 1rem;
  }
}

@media only screen and (max-width: 450px) {
  .form-group > label:first-child {
    background: transparent !important;
    font-size: 16px;
  }

  .wd-sm-none {
    display: none !important;
  }
}

.inputs-group.input-discount {
  width: auto;
}
.title {
  .text-lg-15px {
    font-size: large;
  }
}
