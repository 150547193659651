/* Theme fonts */
@import './assets/css/fonts/fonts';

/* Default Layout Style */
/* Layout */
/* @import "./assets/css/_layout.css"; */
@import "./assets/css/layout/_layout";

/* Main Styles*/
@import "./assets/css/_general";
@import "./assets/css/_print";
@import "./assets/css/custom-style";
@import "./assets/css/ltr";

@tailwind base;
@tailwind components;
@tailwind utilities;