/* === === Variables === === */
.pagination {
  --pagination-gap: 10px;
  --pagination-margin: 15px 0 15px 0;
  /* List */
  --pagination-list-gap: 5px;
  --pagination-list-margin: 0px;
  /* link */
  --pagination-list-link-padding: 5px;
  --pagination-list-link-width: 28px;
  --pagination-list-link-height: 28px;
  --pagination-list-link-bg: var(--clr-gray-1);
  --pagination-list-link-clr: var(--clr-gray-3);
  --pagination-list-link-fs: 14px;
  --pagination-list-link-fw: 400;
  --pagination-list-link-lineheight: 1;
  --pagination-list-link-border-width: 1px;
  --pagination-list-link-border-clr: transparent;
  --pagination-list-link-border-radius: 6px;
  /* link | Controls */
  --pagination-list-link-control-spacing: 10px;
  --pagination-list-link-control-clr: var(--clr-primary);
  --pagination-list-link-control-bg: var(--clr-white);
  --pagination-list-link-control-border-clr: var(--clr-secondary);
  --pagination-list-link-control-fs: 18px;
  --pagination-list-link-control-fw: 400;
  --pagination-list-link-control-lineheight: 20px;
  /* link | active */
  --pagination-list-link-active-clr: var(--clr-white);
  --pagination-list-link-active-bg: var(--clr-primary);
  --pagination-list-link-active-border-clr: var(--clr-primary);
  /* link | disabled */
  --pagination-list-link-disabled-opacity: 0.2;
  
  /* Shown Number || Rows Number */
  --pagination-rownumber-gap: 11px;
  /* select */
  --pagination-rownumber-select-padding: 5px;
  --pagination-rownumber-select-width: auto;
  --pagination-rownumber-select-minwidth: 71px;
  --pagination-rownumber-select-height: 28px;
  --pagination-rownumber-select-bg: var(--clr-white);
  --pagination-rownumber-select-border-radius: 6px;
  --pagination-rownumber-select-icon-width: 10px;
}
/* === === Prefix === === */
.pagination.wd-pagination {

}

/* ==== === ==== === ==== === ==== */
/* === === General Styling === === */
/* Default | Pagination */
.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 1 0 0;
  gap: var(--pagination-gap);
  margin: var(--pagination-margin);
}
@media screen and (max-width: 500px) {.pagination {justify-content: center;}}
.pagination.pagination-disabled {
  opacity: 0.2;
  pointer-events: none;
}
/* List */
.pagination .pagination {gap: var(--pagination-list-gap); margin: var(--pagination-list-margin);}
.pagination .pagination .page-item.disabled a.page-link[aria-label="First"],
.pagination .pagination .page-item.disabled a.page-link[aria-label="Last"],
.pagination .pagination .page-item.disabled a.page-link[aria-label="Previous"],
.pagination .pagination .page-item.disabled a.page-link[aria-label="Next"] {
  opacity: var(--pagination-list-link-disabled-opacity);
}
/* links */
.pagination .pagination .page-item a.page-link {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--pagination-list-link-padding);
  width: var(--pagination-list-link-width);
  height: var(--pagination-list-link-height);
  background-color: var(--pagination-list-link-bg);
  border: var(--pagination-list-link-border-width) solid var(--pagination-list-link-border-clr);
  border-radius: var(--pagination-list-link-border-radius);
  color: var(--pagination-list-link-clr);
  font-size: var(--pagination-list-link-fs);
  font-weight: var(--pagination-list-link-fw);
  line-height: var(--pagination-list-link-lineheight);
}
.pagination .pagination .page-item a.page-link:focus-visible,
.pagination .pagination .page-item a.page-link:focus{outline: 0; box-shadow: none;}
.pagination .pagination .page-item:hover:not(.disabled) a.page-link,
.pagination .pagination .page-item.active a.page-link {
  color: var(--pagination-list-link-active-clr);
  background-color: var(--pagination-list-link-active-bg);
  border-color: var(--pagination-list-link-active-border-clr);
}
/* Previous & Next links && First & Last Links*/
.pagination .pagination .page-item a.page-link[aria-label="First"],
.pagination .pagination .page-item a.page-link[aria-label="Last"],
.pagination .pagination .page-item a.page-link[aria-label="Previous"],
.pagination .pagination .page-item a.page-link[aria-label="Next"] {
  background: var(--pagination-list-link-control-bg);
  color: var(--pagination-list-link-control-clr);
  border-color: var(--pagination-list-link-control-border-clr);
  font-size: var(--pagination-list-link-control-fs);
  font-weight: var(--pagination-list-link-control-fw);
  line-height: var(--pagination-list-link-control-lineheight);
}
.wd-pagination .pagination .page-item:nth-last-child(2){margin-inline-start: var(--pagination-list-link-control-spacing);}
.wd-pagination .pagination .page-item:nth-child(2){margin-inline-end: var(--pagination-list-link-control-spacing);}
/* Shown Number || Rows Number */
.pagination .shown-number {display: flex; align-items: center; gap: var(--pagination-rownumber-gap);}
.pagination .shown-number .ng-select {
  text-align: center;
  padding: var(--pagination-rownumber-select-padding);
  width: var(--pagination-rownumber-select-width);
  height: var(--pagination-rownumber-select-height);
  min-width: var(--pagination-rownumber-select-minwidth);
  background: var(--pagination-rownumber-select-bg);
  border-radius: var(--pagination-rownumber-select-border-radius);
}
.pagination .ng-select .ng-select-container .ng-value-container,
.pagination .ng-select .ng-select-container .ng-value-container .ng-value {justify-content: center;}
.pagination .shown-number .ng-select .ng-arrow-wrapper:after {
  --ngselect-arrow-icon-width: var(--pagination-rownumber-select-icon-width);
  background-size: contain;
}