/* === === Variables === === */
.label{
    --label-display: inline-flex;
    --label-display-Xalignment: center;
    --label-display-Yalignment: center;
    --label-minwidth: 50px;
    --label-gap: 5px;
    --label-padding-block: 5px;
    --label-fs: 14px;
    --label-fw: 400;
    /* --label-lineheight: 20px; */
    --label-bg: var(--clr-secondary);
    --label-border-width: 1px;
    --label-border-clr: transparent;
    --label-border-radius: 5px;
    /* sizes */
    --label-padding-inline-sm: 5px;
    --label-padding-inline-md: 10px;
    --label-padding-inline-lg: 20px;
    --label-padding-inline-xl: 30px;
    --label-height-sm: 25px;
    --label-height-md: 30px;
    --label-height-lg: 40px;
    --label-height-xl: 50px;
    /* icons */
    --label-icon-padding: 5px;
    --label-icon-border-width: 1px;
    --label-icon-border-clr: transparent;
    --label-icon-border-radius: 5px;
    /* icons || sizes */
    --label-icon-width-sm: 25px;
    --label-icon-height-sm: 25px;
    --label-svg-width-sm: 15px;
    --label-svg-height-sm: 15px;
    --label-icon-width-md: 40px;
    --label-icon-height-md: 40px;
    --label-svg-width-md: 25px;
    --label-svg-height-md: 25px;
    --label-icon-width-lg: 55px;
    --label-icon-height-lg: 55px;
    --label-svg-width-lg: 30px;
    --label-svg-height-lg: 30px;
    --label-icon-width-xl: 70px;
    --label-icon-height-xl: 70px;
    --label-svg-width-xl: 50px;
    --label-svg-height-xl: 50px;
}
/* ==== === ==== === ==== === ==== */
/* === === General Styling === === */
.label{
    display: var(--label-display);
    justify-content: var(--label-display-Xalignment);
    align-items: var(--label-display-Yalignment);
    gap: var(--label-gap);
    min-width: var(--label-minwidth);
    height: var(--label-height-md);
    padding: var(--label-padding-block) var(--label-padding-inline-md);
    font-size: var(--label-fs);
    font-weight: var(--label-fw);
    background-color: var(--label-bg);
    border: var(--label-border-width) solid var(--label-border-clr);
    border-radius: var(--label-border-radius);
}
/* Sizes */
.label:not(.label-icon).label-sm{padding-inline: var(--label-padding-inline-sm); height: var(--label-height-sm);}
.label:not(.label-icon).label-md{padding-inline: var(--label-padding-inline-md); height: var(--label-height-md);}
.label:not(.label-icon).label-lg{padding-inline: var(--label-padding-inline-lg); height: var(--label-height-lg);}
.label:not(.label-icon).label-xl{padding-inline: var(--label-padding-inline-xl); height: var(--label-height-xl);}
/* Icons */
.label.label-icon {
    min-width: auto;
    padding: var(--label-icon-padding);
    width: var(--label-icon-width-md);
    height: var(--label-icon-height-md);
    border: var(--label-icon-border-width) solid var(--label-icon-border-clr);
    border-radius: var(--label-icon-border-radius);
}
.label.label-icon .svg-icon{width: var(--label-svg-width-md); height: var(--label-svg-height-md);}
/* Icon || Sizes */
.label.label-icon.label-sm{width: var(--label-icon-width-sm); height: var(--label-icon-height-sm);}
.label.label-icon.label-sm .svg-icon{width: var(--label-svg-width-sm); line-height: var(--label-svg-height-sm);}
.label.label-icon.label-md{width: var(--label-icon-width-md); height: var(--label-icon-height-md);}
.label.label-icon.label-md .svg-icon{width: var(--label-svg-width-md); line-height: var(--label-svg-height-md);}
.label.label-icon.label-lg{width: var(--label-icon-width-lg); height: var(--label-icon-height-lg);}
.label.label-icon.label-lg .svg-icon{width: var(--label-svg-width-lg); line-height: var(--label-svg-height-lg);}
.label.label-icon.label-xl{width: var(--label-icon-width-xl); height: var(--label-icon-height-xl);}
.label.label-icon.label-xl .svg-icon{width: var(--label-svg-width-xl); line-height: var(--label-svg-height-xl);}
/* Colors */
.label.label-primary{background-color: var(--clr-primary); color: var(--clr-white);}
.label.label-primary .svg-icon{color: var(--clr-white);}
.label.label-success{background-color: var(--clr-success); color: var(--clr-white);}
.label.label-success .svg-icon{color: var(--clr-white);}
.label.label-warning{background-color: var(--clr-warning); color: var(--clr-white);}
.label.label-warning .svg-icon{color: var(--clr-white);}
.label.label-danger{background-color: var(--clr-danger); color: var(--clr-white);}
.label.label-danger .svg-icon{color: var(--clr-white);}
/* ==== === ==== === ==== === ==== */
/* === ==== Prefix ==== === */
/* ==== === ==== === ==== === ==== */
.label.wd-label{
    /* sizes */
    --label-padding-inline-sm: 5px;
    --label-padding-inline-md: 10px;
    --label-padding-inline-lg: 20px;
    --label-padding-inline-xl: 30px;
    --label-height-sm: 25px;
    --label-height-md: 30px;
    --label-height-lg: 55px;
    --label-height-xl: 70px;
    /* Icon */
    --label-icon-width-md: 32px;
    --label-icon-height-md: 32px;
    --label-svg-width-md: 20px;
    --label-svg-height-md: 20px;
    --label-icon-border-radius: 5px;
    
}
/* Prefix || Custom Classes */
.wd-label.info-label{
    --label-display: flex;
    --label-display-Xalignment: flex-start;
    --label-display-Yalignment: flex-start;
    --label-bg: var(--clr-gray-2);
    --label-padding-block: 10px;
    --label-padding-inline-md: 10px;
    --label-border-width: 1px;
    --label-border-clr: var(--clr-secondary);
    --label-border-radius: 5px;

    min-height: 40px;

}
.wd-label.info-label.label-center{--label-display-Yalignment: center;}
/* Prefix || Colors */
.label.wd-label.label-primary{background-color: rgba(23, 105, 204, 0.1); color: var(--clr-primary);}
.label.wd-label.label-primary .svg-icon{color: var(--clr-primary);}
.label.wd-label.label-success{background-color: rgba(97, 197, 134, 0.15); color: var(--clr-success);}
.label.wd-label.label-success .svg-icon{color: var(--clr-success);}
.label.wd-label.label-warning{background-color: rgba(255, 196, 58, 0.2); color: var(--clr-warning);}
.label.wd-label.label-warning .svg-icon{color: var(--clr-warning);}
.label.wd-label.label-danger{background-color: rgba(230, 101, 101, 0.1); color: var(--clr-danger);}
.label.wd-label.label-danger .svg-icon{color: var(--clr-danger);}
.label.wd-label.label-primary-2{background-color: var(--clr-light-primary-2); color: var(--clr-primary);}
.label.wd-label.label-primary-2 .svg-icon{color: var(--clr-primary);}
.label.wd-label.label-dark-primary{background-color: var(--clr-light-blue); color: var(--clr-primary);}
.label.wd-label.label-dark-primary .svg-icon{color: var(--clr-primary);}
.label.wd-label.label-success-1{background-color: var(--clr-success); color: var(--clr-white);}
.label.wd-label.label-success-1 .svg-icon{color: var(--clr-white);}
.label.wd-label.label-warning-1{background-color: var(--clr-orange-2); color: var(--clr-white);}
.label.wd-label.label-warning-1 .svg-icon{color: var(--clr-white);}
.label.wd-label.label-orange{background-color: var(--clr-orange-1); color: var(--clr-orange);}
.label.wd-label.label-orange .svg-icon{color: var(--clr-orange);}
.label.wd-label.label-orange-1{background-color: var(--clr-orange); color: var(--clr-white);}
.label.wd-label.label-orange-1 .svg-icon{color: var(--clr-white);}
.label.wd-label.label-danger-1{background-color: var(--clr-danger); color: var(--clr-white);}
.label.wd-label.label-danger-1 .svg-icon{color: var(--clr-white);}
.label.wd-label.label-red{background-color: rgba(230, 101, 101, 0.22); color: var(--clr-danger);}
.label.wd-label.label-red .svg-icon{color: var(--clr-danger);}
.label.wd-label.label-gray{background-color: var(--bg-body); color: var(--clr-body);}
.label.wd-label.label-gray .svg-icon{color: var(--clr-body);}
.label.wd-label.label-light-gray-3{background-color: rgba(149, 178, 203, 0.2); color: var(--clr-gray-3);}
.label.wd-label.label-light-gray-3 .svg-icon{color: var(--clr-gray-3);}
/* Prefix || Icon || Colors */
.label.wd-label.label-icon.label-primary{background-color: rgba(23, 105, 204, 0.2);}
.label.wd-label.label-icon.label-primary .svg-icon{color: var(--clr-primary);}
.label.wd-label.label-icon.label-light-primary{background-color: rgba(146, 192, 229, 0.2);}
.label.wd-label.label-icon.label-light-primary .svg-icon{color: var(--clr-light-blue-2);}
.label.wd-label.label-icon.label-success{background-color: rgba(97, 197, 134, 0.2);}
.label.wd-label.label-icon.label-success .svg-icon{color: var(--clr-success);}
.label.wd-label.label-icon.label-warning{background-color: rgba(255, 196, 58, 0.25);}
.label.wd-label.label-icon.label-warning .svg-icon{color: var(--clr-warning);}
.label.wd-label.label-icon.label-orange{background-color: var(--clr-orange-1);}
.label.wd-label.label-icon.label-orange .svg-icon{color: var(--clr-orange);}
.label.wd-label.label-icon.label-danger{background-color: rgba(230, 101, 101, 0.2);}
.label.wd-label.label-icon.label-danger .svg-icon{color: var(--clr-danger);}

/* ====================  */
/* Alerts */
/* === === Variables === === */
.alert{
    --alert-bg: var(--clr-white);
    --alert-border-width: 1px;
    --alert-border-clr: var(--clr-gray);
    --alert-boxshadow: 0px 20px 20px rgba(111, 129, 145, 0.03);
    /* icon */
    --alert-icon-width-md: 35px;
    --alert-icon-height-md: 35px;
    --alert-icon-clr: var(--clr-white);
    --alert-icon-bg: var(--clr-gray);
    --alert-icon-border-radius-md: 50%;
    --alert-icon-svg-width-md: 30px;
    --alert-icon-svg-height-md: 30px;
    /* content */
    --alert-content-clr: var(--clr-dark);
    --alert-content-fw: 400;
    --alert-content-lineheight-md: 25px;
    /* Close */
    --alert-close-ie: 10px;
    --alert-close-bs: 10%;

    /* SIZES */
    /* sm */
    --alert-gap-sm: 10px;
    --alert-padding-block-sm: 10px;
    --alert-padding-inline-sm: 10px 50px;
    --alert-border-radius-sm: 6px;
    --alert-icon-width-sm: 20px;
    --alert-icon-height-sm: 20px;
    --alert-icon-svg-width-sm: 15px;
    --alert-icon-svg-height-sm: 15px;
    --alert-icon-border-radius-sm: 50%;
    --alert-content-fs-sm: 14px;
    --alert-content-lineheight-sm: 20px;
    /* --- --- --- */
    /* md */
    --alert-gap-md: 10px;
    --alert-padding-block-md: 10px;
    --alert-padding-inline-md: 10px 50px;
    --alert-border-radius-md: 10px;
    --alert-icon-width-md: 25px;
    --alert-icon-height-md: 25px;
    --alert-icon-svg-width-md: 20px;
    --alert-icon-svg-height-md: 20px;
    --alert-icon-border-radius-md: 50%;
    --alert-content-fs-md: 16px;
    --alert-content-lineheight-md: 20px;
    /* --- --- --- */
    /* lg */
    --alert-gap-lg: 10px;
    --alert-padding-block-lg: 10px;
    --alert-padding-inline-lg: 10px 50px;
    --alert-border-radius-lg: 10px;
    --alert-icon-width-lg: 40px;
    --alert-icon-height-lg: 40px;
    --alert-icon-svg-width-lg: 30px;
    --alert-icon-svg-height-lg: 30px;
    --alert-icon-border-radius-lg: 50%;
    --alert-content-fs-lg: 16px;
    --alert-content-lineheight-lg: 25px;
    /* --- --- --- */
    /* xl */
    --alert-gap-xl: 10px;
    --alert-padding-block-xl: 10px;
    --alert-padding-inline-xl: 10px 50px;
    --alert-border-radius-xl: 10px;
    --alert-icon-width-xl: 50px;
    --alert-icon-height-xl: 50px;
    --alert-icon-svg-width-xl: 40px;
    --alert-icon-svg-height-xl: 40px;
    --alert-icon-border-radius-xl: 50%;
    --alert-content-fs-xl: 16px;
    --alert-content-lineheight-xl: 25px;
    /* --- --- --- */
}
/* ==== === ==== === ==== === ==== */
/* === === General Styling === === */
.alert{
    position: relative;
    display: flex;
    align-items: center;
    gap: var(--alert-gap-md);
    padding-block: var(--alert-padding-block-md);
    padding-inline: var(--alert-padding-inline-md);
    background-color: var(--alert-bg);
    border: var(--alert-border-width) solid var(--alert-border-clr);
    border-radius: var(--alert-border-radius-md);
    box-shadow: var(--alert-boxshadow);
}
/* Icon */
.alert .alert-icon{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: var(--alert-icon-width-md);
    height: var(--alert-icon-height-md);
    color: var(--alert-icon-clr);
    background: var(--alert-icon-bg);
    border-radius: var(--alert-icon-border-radius-md);
}
.alert .alert-icon .svg-icon svg{width: var(--alert-icon-svg-width-md); height: var(--alert-icon-svg-height-md);}
/* Content */
.alert .alert-content p{
    color: var(--alert-content-clr);
    font-size: var(--alert-content-fs-md);
    font-weight: var(--alert-content-fw);
    line-height: var(--alert-content-lineheight-md);
}
/* Close */
.alert .alert-close{position: absolute; inset-inline-end: var(--alert-close-ie); inset-block-start: var(--alert-close-bs);}
/* ==== === Sizes === ==== */
/* sm */
.alert.alert-sm{
    padding-block: var(--alert-padding-block-sm);
    padding-inline: var(--alert-padding-inline-sm);
    gap: var(--alert-gap-sm);
    border-radius: var(--alert-border-radius-sm); 
}
.alert.alert-sm .alert-icon,
.alert.alert-sm .alert-icon .svg-icon{width: var(--alert-icon-width-sm); height: var(--alert-icon-height-sm);}
.alert.alert-sm .alert-icon svg{width: var(--alert-icon-svg-width-sm); height: var(--alert-icon-svg-height-sm);}
.alert.alert-sm .alert-content p{font-size: var(--alert-content-fs-sm); line-height: var(--alert-content-lineheight-sm);}
/* md */
.alert.alert-md{
    padding-block: var(--alert-padding-block-md);
    padding-inline: var(--alert-padding-inline-md);
    gap: var(--alert-gap-md);
    border-radius: var(--alert-border-radius-md); 
}
.alert.alert-md .alert-icon,
.alert.alert-md .alert-icon .svg-icon{width: var(--alert-icon-width-md); height: var(--alert-icon-height-md);}
.alert.alert-md .alert-icon svg{width: var(--alert-icon-svg-width-md); height: var(--alert-icon-svg-height-md);}
.alert.alert-md .alert-content p{font-size: var(--alert-content-fs-md); line-height: var(--alert-content-lineheight-md);}
/* lg */
.alert.alert-lg{
    padding-block: var(--alert-padding-block-lg);
    padding-inline: var(--alert-padding-inline-lg);
    gap: var(--alert-gap-lg);
    border-radius: var(--alert-border-radius-lg); 
}
.alert.alert-lg .alert-icon,
.alert.alert-lg .alert-icon .svg-icon{width: var(--alert-icon-width-lg); height: var(--alert-icon-height-lg);}
.alert.alert-lg .alert-icon svg{width: var(--alert-icon-svg-width-lg); height: var(--alert-icon-svg-height-lg);}
.alert.alert-lg .alert-content p{font-size: var(--alert-content-fs-lg); line-height: var(--alert-content-lineheight-lg);}
/* xl */
.alert.alert-xl{
    padding-block: var(--alert-padding-block-xl);
    padding-inline: var(--alert-padding-inline-xl);
    gap: var(--alert-gap-xl);
    border-radius: var(--alert-border-radius-xl); 
}
.alert.alert-xl .alert-icon,
.alert.alert-xl .alert-icon .svg-icon{width: var(--alert-icon-width-xl); height: var(--alert-icon-height-xl);}
.alert.alert-xl .alert-icon svg{width: var(--alert-icon-svg-width-xl); height: var(--alert-icon-svg-height-xl);}
.alert.alert-xl .alert-content p{font-size: var(--alert-content-fs-xl); line-height: var(--alert-content-lineheight-xl);}
/* ==== === Colors === ==== */
/* Primary */
.alert.alert-primary{border-color: var(--clr-primary); background: var(--clr-light-primary);}
.alert.alert-primary .alert-icon{background: var(--clr-primary);}
.alert.alert-primary .alert-close .btn,
.alert.alert-primary .alert-content a{color: var(--clr-primary);}
/* Secondary */
.alert.alert-secondary{border-color: var(--clr-secondary); background: var(--clr-white);}
.alert.alert-secondary .alert-icon{background: var(--clr-secondary);}
.alert.alert-secondary .alert-close .btn,
.alert.alert-secondary .alert-content a{color: var(--clr-secondary);}
/* Success */
.alert.alert-success{border-color: var(--clr-success); background: var(--clr-light-success);}
.alert.alert-success .alert-icon{background: var(--clr-success);}
.alert.alert-success .alert-close .btn,
.alert.alert-success .alert-content a{color: var(--clr-success);}
/* Warning */
.alert.alert-warning{border-color: var(--clr-warning); background: var(--clr-light-warning);}
.alert.alert-warning .alert-icon{background: var(--clr-warning);}
.alert.alert-warning .alert-close .btn,
.alert.alert-warning .alert-content a{color: var(--clr-warning);}
/* Danger */
.alert.alert-danger{border-color: var(--clr-light-danger-2); background: var(--clr-light-danger-1);}
.alert.alert-danger .alert-content{color: var(--clr-danger);}
.alert.alert-danger .alert-icon{background: var(--clr-light-danger-1);}
.alert.alert-danger .alert-close .btn{color: var(--clr-primary);}
.alert.alert-danger .alert-content a{color: var(--clr-danger);}
/* ==== === ==== === ==== === ==== */
/* === ==== Prefix ==== === */
.alert.wd-alert{
    --alert-border-radius-md: 10px;
    --alert-padding-inline-md: 25px 65px;
    --alert-content-clr: inherit;
    --alert-content-fs: 16px;
    --alert-content-fw: 500;
    
    .alert-content p a{text-decoration: underline;}
    
    .alert-close{
        inset: 0;
        margin: auto;
        display: inline-flex;
        inset-inline-end: var(--alert-close-ie);
        justify-content: flex-end;
    }

    &.alert-info{
        border: 0;
        padding: 5px;
        box-shadow: none;

        p{
            font-size: 18px;
            font-weight: 700;
            color: var(--clr-secondary);
        }
    }
}
/* ==== === ==== === ==== === ==== */
/* === ==== CUSTOM ==== === */
/* Prefix || Custom Classes */
/* 2 Border */
.wd-alert.alert-2-border,
.wd-alert.alert-2-border[class*='alert-']{
    --alert-gap-md: 15px;
    --alert-padding-block-md: 15px;
    --alert-padding-inline-md: 15px 50px;
    --alert-gap-lg: 20px;
    --alert-padding-block-lg: 20px;
    --alert-padding-inline-lg: 20px 50px;
    --alert-gap-xl: 20px;
    --alert-padding-block-xl: 20px;
    --alert-padding-inline-xl: 20px 50px;
}
.wd-alert.alert-2-border .alert-icon{box-shadow: 0 0 0 3px var(--clr-white), 0 0 0 5px var(--clr-gray);}
/* Dashboard Alert */
.wd-alert.dashboard-alert,
.wd-alert.dashboard-alert[class*='alert-']{
    border-color: transparent;
    --alert-padding-block-md: 15px;
    --alert-padding-inline-md: 15px 50px;
    --alert-gap-md: 10px;
    --alert-border-radius-md: 6px;
    --alert-padding-block-lg: 20px;
    --alert-padding-inline-lg: 20px 50px;
    --alert-gap-lg: 10px;
    --alert-border-radius-lg: 6px;
    --alert-padding-block-xl: 20px;
    --alert-padding-inline-xl: 20px 50px;
    --alert-gap-xl: 10px;
    --alert-border-radius-xl: 6px;
}
.wd-alert.dashboard-alert .alert-icon,
.wd-alert.dashboard-alert[class*='alert-'] .alert-icon{
    background: none;
    --alert-border-radius-md: 0px;
    --alert-border-radius-lg: 0px;
    --alert-border-radius-xl: 0px;
}
.alert.wd-alert.dashboard-alert .alert-icon,
.alert.alert.wd-alert.dashboard-alert .alert-icon .svg-icon,
.alert.alert.wd-alert.dashboard-alert .alert-icon .svg-icon svg {width: 39px; height: 36px;}
.alert.wd-alert.dashboard-alert .alert-close{--alert-close-ie: 15px; margin: auto; --alert-close-bs: auto;}
.alert.wd-alert.dashboard-alert .alert-close .btn{border-radius: 3px;}
/* Prefix || Colors */
/* Primary */
.wd-alert.alert-primary{background: var(--clr-light-blue-1);}
/* Primary || Custom Classes */
.wd-alert.alert-primary.alert-2-border,
.wd-alert.alert-primary.dashboard-alert{background: var(--clr-white);}
.wd-alert.alert-primary.alert-2-border .alert-icon{box-shadow: 0 0 0 3px var(--clr-white), 0 0 0 5px var(--clr-primary);}
/* secondary */
.wd-alert.alert-secondary{border-color: var(--clr-dark); background: var(--clr-gray-1);}
.wd-alert.alert-secondary .alert-icon{background: var(--clr-dark);}
.wd-alert.alert-secondary .alert-close .btn,
.wd-alert.alert-secondary .alert-content a{color: var(--clr-dark);}
/* Secondary || Custom Classes */
.wd-alert.alert-secondary.alert-2-border,
.wd-alert.alert-secondary.dashboard-alert{background: var(--clr-white);}
.wd-alert.alert-secondary.alert-2-border .alert-icon{box-shadow: 0 0 0 3px var(--clr-white), 0 0 0 5px var(--clr-dark);}
/* Success */
/* Success || Custom Classes */
.wd-alert.alert-success.alert-2-border,
.wd-alert.alert-success.dashboard-alert{background: var(--clr-white);}
.wd-alert.alert-success.alert-2-border .alert-icon{box-shadow: 0 0 0 3px var(--clr-white), 0 0 0 5px var(--clr-success);}
/* Warning */
.wd-alert.alert-warning{background: var(--clr-light-orange);}
/* Warning || Custom Classes */
.wd-alert.alert-warning.alert-2-border,
.wd-alert.alert-warning.dashboard-alert{background: var(--clr-white);}
.wd-alert.alert-warning.alert-2-border .alert-icon{box-shadow: 0 0 0 3px var(--clr-white), 0 0 0 5px var(--clr-warning);}
/* Orange */
.wd-alert.alert-orange{border-color: var(--clr-orange); background: var(--clr-light-orange);}
.wd-alert.alert-orange .alert-icon{background: var(--clr-orange);}
.wd-alert.alert-orange .alert-close .btn,
.wd-alert.alert-orange .alert-content a{color: var(--clr-orange);}
/* Orange || Custom Classes */
.wd-alert.alert-orange.alert-2-border,
.wd-alert.alert-orange.dashboard-alert{background: var(--clr-white);}
.wd-alert.alert-orange.alert-2-border .alert-icon{box-shadow: 0 0 0 3px var(--clr-white), 0 0 0 5px var(--clr-orange);}
/* Danger */
// .wd-alert.alert-danger{background: rgba(230, 101, 101, 0.15);}
/* Danger || Custom Classes */
.wd-alert.alert-danger.alert-2-border,
.wd-alert.alert-danger.dashboard-alert{background: var(--clr-white);}
.wd-alert.alert-danger.alert-2-border .alert-icon{box-shadow: 0 0 0 3px var(--clr-white), 0 0 0 5px var(--clr-danger);}