.dropdown .dropdown-menu {
    min-width: 10rem;
    padding: 1rem 0;
    border: 0.1rem solid var(--clr-gray-4);
    border-radius: 0.8rem;
    text-align: center;
    line-height: 1;
    color: var(--clr-body);
    background: var(--clr-white);
    box-shadow: 0.5rem 0.5rem 1.5rem rgba(111, 129, 145, 0.1);
}
.dropdown .dropdown-menu::before{
    /*content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 1rem 1.5rem 1rem;
    border-color: transparent transparent var(--clr-white) transparent;
    position: absolute;
    top: -1rem;
    inset-inline-end: 0.5rem;*/
}
.dropdown .dropdown-toggle{
    /* padding: 0; */
    background: transparent;
    border-color: transparent;
    line-height: 1;
    /* height: auto; */
}
.dropdown .dropdown-toggle::after {display: none;}
.dropdown .dropdown-menu .btn-dropdown:not(.dropdown-toggle){
    padding: 0.5rem 1.5rem;
    color: var(--clr-body);
    font-size: var(--fs-sm);
    font-weight: 400;
    height: auto;
    width: 100%;
    border-radius: 0;
    background-color: transparent;
    border-color: transparent;
}
.dropdown .dropdown-menu .btn-dropdown:not(.dropdown-toggle):hover {
    background: var(--clr-secondary);
}

/* Aside menu || Company dropdown || bug fixed */
.company-dropdown .dropdown-menu {inset-inline-start: -10px !important;}
.dropdown.company-dropdown  .dropdown-menu::before {inset-inline-end: auto; inset-inline-start: 0.5rem;}

.dropdown-position {
    //transform: translate(125px, 35px) !important;
}