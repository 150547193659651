.tablist{text-align: center;}
.tablist ul.nav {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    padding: 1rem 2rem;
    background: var(--clr-white);
    border-radius: 1.5rem;
    box-shadow: 0rem 2rem 2rem rgb(111 129 144 / 3%)
}
.tablist ul.nav li.nav-item .nav-link {
    font-size: 1.6rem;
    font-weight: 400;
    color: var(--clr-body);
    border-radius: 1.5rem;
    padding: 1rem 2rem;
    background: transparent;
}
.tablist ul.nav li.nav-item .nav-link.active,
.tablist ul.nav li.nav-item .nav-link:hover{background: var(--clr-primary); color: var(--clr-white);}
.tablist .tab-content{text-align: initial;}
.tablist.wd-tablist ul.nav {
    justify-content: flex-start;
    display: flex;
    background: transparent;
    box-shadow: none;
}
.tablist.wd-tablist ul.nav li.nav-item .nav-link {
    padding: 0.9rem 1rem;
    min-width: 21.5rem;
    color: var(--clr-white);
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    background-color: var(--clr-light-blue-2);
    border-radius: 0.6rem;
}
.tablist.wd-tablist ul.nav li.nav-item .nav-link.active,
.tablist.wd-tablist ul.nav li.nav-item .nav-link:hover{background: var(--clr-primary); color: var(--clr-white);}


/* Tablist || Line */
.tablist.tablist-line ul.nav {
  box-shadow: none;
  padding: 0;
  gap: 1rem;
  border-radius: 0;
}
.tablist.tablist-line ul.nav li.nav-item .nav-link {
  font-size: 1.4rem;
  font-weight: 700;
  border-radius: 0;
  padding: 1rem;
  position: relative;
}
.tablist.tablist-line ul.nav li.nav-item .nav-link.active,
.tablist.tablist-line ul.nav li.nav-item .nav-link:hover {color: var(--clr-primary); background: transparent;}
.tablist.tablist-line ul.nav li.nav-item .nav-link:before,
.tablist.tablist-line ul.nav li.nav-item .nav-link:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  inset-inline: 0;
  bottom: -0.6rem;
  background: transparent;
}
.tablist.tablist-line ul.nav li.nav-item .nav-link.active:before,
.tablist.tablist-line ul.nav li.nav-item .nav-link:hover:before {background: var(--clr-primary);}

.tablist.tablist-line {
  ul.nav {
    gap: 4rem;
    box-shadow: 0px 3px 5px -3px rgba(0,0,0,0.2);
    border-bottom: 1px solid rgba(34,35,51,0.12);
    padding-inline: 4rem 1rem;

    li.nav-item{
      .nav-link {
        text-align: right;
        padding-inline: 0;
        padding-inline-end: 10px;
        min-width: auto;
        padding-bottom: 25px;
        color: var(--clr-secondary);
        font-size: 2rem;
        font-weight: 700;

        &::before{
          height: 3px;
          bottom: -1.5px;
          border-radius: 5px;
        }

        // &:hover,
        // &.active{font-weight: bold;}
      }
    }
  }
}