@import "./_header";
@import "./_footer";
@import "./_scrolltop";

.wrapper {
  //padding: 4rem;
  /* transition: 0.125s all ease-in-out; */
  transition: 0.3s all ease-in-out;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 6rem;
    inset-inline-end: 0;
    width: 17rem;
    height: 53rem;
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: top;
    z-index: -1;
  }
  &.wrapper-gold::before {
    background: url("../../media/imgs/pattern-gold.png");
  }
}
.flex-root {
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex: 1 0 0;
  min-height: 100vh;
}
.flex-column-fluid {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}
.flex-row-fluid {
  -webkit-box-flex: 1;
  flex: 1 auto;
  -ms-flex: 1 0 0;
  min-width: 0;
}
/* aside */
.flex-row-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
