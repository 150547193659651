/* === === Variables === === */
.card {
  --card-padding: 10px;
  --card-mb: 30px;
  --card-bg: var(--clr-white);
  --card-border-width: 1px;
  --card-border-clr: transparent;
  --card-border-radius: 15px;
  --card-boxshadow: 0;
  /* Header */
  --card-header-padding: 0 10px;
  --card-header-bg: transparent;
  --card-header-borderbottom-width: 0px;
  --card-header-borderbottom-clr: transparent;
  --card-header-mobile-gap: 10px;
  --card-header-border-radius: var(--card-border-radius)
    var(--card-border-radius) 0 0;
  /* Header || title */
  --card-header-title-marginblock: 0px;
  --card-header-title-margininline: 0px;
  --card-header-title-clr: var(--clr-dark);
  --card-header-title-fs: 20px;
  --card-header-title-fw: 700;
  --card-header-title-linheight: 1.5;
  /* Header || subtitle */
  --card-header-subtitle-marginblock: 0px;
  --card-header-subtitle-margininline: 5px;
  --card-header-subtitle-clr: var(--clr-gray);
  --card-header-subtitle-fs: 14px;
  --card-header-subtitle-fw: 700;
  --card-header-subtitle-linheight: 1.5;
  /* Header || Toolbar */
  --card-header-toolbar-gap: 0px;
  --card-header-toolbar-marginblock: 10px;
  --card-header-toolbar-margininline: 0px;
  /* Body */
  --card-body-padding: 20px 10px;
  /* Footer */
  --card-footer-padding: 20px 10px;
  --card-footer-gap: 10px;
  --card-footer-bg: var(--clr-white);
  --card-footer-bordertop-width: 1px;
  --card-footer-bordertop-clr: var(--clr-secondary-3);
  --card-footer-action-gap: 20px;
}
/* === === Prefix === === */
.card.wd-card {
  --card-padding: 20px;
  --card-border-radius: 15px;
  --card-boxshadow: 0px 20px 20px rgba(111, 129, 144, 0.03);
  /* Header */
  --card-header-padding: 0px 0px 20px 0;
  /* Header || title */
  --card-header-title-marginblock: 0px;
  --card-header-title-margininline: 0px;
  --card-header-title-fs: 18px;
  /* Header || toolbar */
  --card-header-toolbar-gap: 20px;
  --card-header-toolbar-marginblock: 0px;
  --card-header-toolbar-margininline: 0px;
  /* Body */
  --card-body-padding: 0px;
  /* Footer */
  --card-footer-padding: 20px 0px 0px 0px;
}
.card.wd-card.card-requests {
  --card-padding: 0px;
  --card-boxshadow: none;
  // header
  --card-border-radius: 16px;
  --card-header-padding: 22px 30px;
  --card-header-bg: var(--clr-primary);

  --card-header-title-clr: var(--clr-white);
  --card-header-title-fs: 24px;
  --card-header-title-fw: 500;
  --card-header-title-linheight: 27px;
  --card-header-subtitle-clr: var(--clr-warning);
  --card-header-subtitle-fs: 16px;
  --card-header-subtitle-fw: 500;
  --card-header-subtitle-linheight: 20px;
  --card-header-subtitle-marginblock: 5px 0;
  --card-body-padding: 30px 25px 10px;
  --card-bg: var(--clr-gray-4);

  .card-toolbar p {
    font-size: 36px;
    font-weight: 500;
    color: var(--clr-white);
    span {
      font-size: 20px;
    }
  }

  .card-content {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
    flex-wrap: wrap;

    h3 {
      margin-bottom: 0;
    }
    p {
      color: var(--clr-secondary);
      font-size: 17px;
    }
  }

  .card-actions {
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
    gap: 10px;



    > .btn.wd-btn {
      font-size: 14px;
      font-weight: bold;
      padding-block: 11px;
    }
  }
}
.card.wd-card.card-wallet {
  --card-padding: 30px 30px 50px;
  --card-border-radius: 12px;
  --card-boxshadow: none;
  --card-body-padding: 0px;
  border: none !important;

  .card-content {
    h3 {
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      color: var(--clr-body);
      margin-bottom: 10px;
    }
    h4 {
      font-size: 20px;
      font-weight: 700;
      line-height: 40px;
      color: var(--clr-warning);
      margin-bottom: 5px;
    }
    p {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      color: var(--clr-secondary);
      margin-bottom: 15px;
    }
  }
  .card-icon {
    text-align: end;
    padding-inline-end: 20px;
  }

  &::before {
    content: "";
    position: absolute;
    width: 180px;
    height: 130px;
    inset-inline-end: 0px;
    bottom: 0;
    background-image: url("../../media/icons/wallet-bg.svg");
    background-repeat: no-repeat;
    background-position: center;
  }

  &.wallet-primary {
    --card-bg: var(--clr-light-primary-2);
    h4 {
      color: var(--clr-primary);
    }
  }
  &.wallet-warning {
    --card-bg: var(--clr-light-warning-2);

    &::before {
      background-image: url("../../media/icons/investement-bg.svg");
    }
  }
  &.wallet-secondary {
    --card-bg: var(--clr-light-secondary-1);
  }
  &.wallet-danger {
    --card-bg: var(--clr-light-danger-3);
    h4 {
      color: var(--clr-danger);
    }
  }
}

.card.wd-card.card-responsive {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.12);
  border: none;

  .card-content {
    justify-content: start;

    h3 {
      font-weight: bold;
      font-size: 16px;
    }

    p {
      color: #111827;
      font-weight: 400;
      font-size: 14px;
    }
  }

  img {
    width: 40px;
  }
}

/* ==== === ==== === ==== === ==== */
/* === === General Styling === === */
/* Card | Default */
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  padding: var(--card-padding);
  background-color: var(--card-bg);
  border: var(--card-border-width) solid var(--card-border-clr);
  border-radius: var(--card-border-radius);
  margin-bottom: var(--card-mb);
  box-shadow: var(--card-boxshadow);
}
.card.card-stretch {
  display: flex;
  align-items: stretch !important;
  flex-direction: column;
  height: calc(100% - var(--card-mb));
}
/* Header */
.card .card-header {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  padding: var(--card-header-padding);
  background-color: var(--card-header-bg);
  border-bottom: var(--card-header-borderbottom-width) solid
    var(--card-header-borderbottom-clr);
  border-radius: var(--card-header-border-radius);
}
@media screen and (max-width: 549.98px) {
  .card .card-header {
    gap: var(--card-header-mobile-gap);
  }
}
.card .card-header .card-title {
  display: flex;
  align-items: center;
  margin-block: var(--card-header-title-marginblock);
  margin-inline: var(--card-header-title-margininline);
}
.card .card-header .card-title h3 {
  color: var(--card-header-title-clr);
  font-size: var(--card-header-title-fs);
  font-weight: var(--card-header-title-fw);
  line-height: var(--card-header-title-linheight);
}
.card .card-header .card-title .card-subtitle {
  margin-block: var(--card-header-subtitle-marginblock);
  margin-inline: var(--card-header-subtitle-margininline);
  color: var(--card-header-subtitle-clr);
  font-size: var(--card-header-subtitle-fs);
  font-weight: var(--card-header-subtitle-fw);
  line-height: var(--card-header-subtitle-linheight);
}
.card .card-header .card-toolbar {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--card-header-toolbar-gap);
  margin-block: var(--card-header-toolbar-marginblock);
  margin-inline: var(--card-header-toolbar-margininline);
}
/* Body */
.card .card-body {
  padding: var(--card-body-padding);
}
/* Footer */
.card .card-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--card-footer-gap);
  padding: var(--card-footer-padding);
  background-color: var(--card-footer-bg);
  border-top: var(--card-footer-bordertop-width) solid
    var(--card-footer-bordertop-clr);
}
.card .card-footer .card-action {
  display: flex;
  align-items: center;
  gap: var(--card-footer-action-gap);
}
.card .card-footer .card-action > * {
  flex: 1;
}
@media screen and (max-width: 767.98px) {
  .card .card-footer {
    flex-direction: column;
  }
  .card-action {
    flex: 1;
    width: 100%;
  }

  .card.wd-card.card-wallet {
    .card-content {
      h3 {
        font-size: 20px;
      }
    }
  }
}
/* @media screen and (min-width:1200px){.card-action.action-xl-25{flex:0.25}}
@media screen and (min-width:992px){.card-action{flex:0.35}}
@media screen and (min-width:768px) and (max-width: 991.98px){.card-action{flex:0.5}} */
/* ==== === ==== === ==== === ==== */
/* Footer || Custom Classes */
/* Footer mobile -half width || footer-m-50 */
.card.wd-card .card-footer.footer-m-50 > * {
  flex: 1;
}

.card.wd-card.dashboard-card {
  border: none;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.08);
  position: relative;

  .card-body {
    margin-bottom: 50px;

    .card-content {
      h3 {
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        color: var(--clr-body);
        margin-bottom: 10px;
      }
  
      h4 {
        font-size: 16px;
        font-weight: 700;
        line-height: 40px;
        color: var(--clr-warning);
        margin-bottom: 5px;
        
        span {
          color: var(--clr-secondary);
          font-size: 14px;
        }
      }
  
      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 28px;
        color: var(--clr-secondary);
        margin-bottom: 15px;
      }
    }
  }

  .card-footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 50px;
    display: block;
    padding: 0;

    .row {
      width: 100%;
      align-items: center;
      margin: 0;
      height: 100%;
    }
    
    h3 {
      font-size: 16px;
      font-weight: 700;
      color: #fff;
    }

    span.svg-icon {
      width: 30px;
      position: relative;
      top: 4px
    }
  }
}

.cart-card {
  border: 1px solid #F1F1F2;
  border-radius: 8px;
  padding: 15px;
  min-height: 280px;

  .cart-card-header {
    padding-bottom: 15px;
    border-bottom: 1px dashed #E1E3EA;

    .row {
      align-items: center;

      h3 {
        font-size: 18px;
        font-weight: 700;
        color: var(--clr-primary);
      }
  
      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 28px;
        color: var(--clr-secondary);
      }
    }
  }

  .cart-card-content {
    .no-data {
      text-align: center;
      transform: translate(0, 50%);
      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 28px;
        color: var(--clr-secondary);
        margin-bottom: 15px;
      }
    }
  }
}

.card.card-cta {
  --card-bg: var(--clr-light-primary-2);
  border: none;
  text-align: center;
  
  h3 {
    font-size: 18px;
    font-weight: 700;
    color: var(--clr-primary);
    width: 75%;
    margin: auto;
  }

  img {
    width: 300px;
    margin: auto;
    padding: 20px 0;
  }
}

.card.latest-card {
  min-height: 447px;
  
  .row {
    h3 {
      font-weight: 700;
      line-height: 28px;
      color: var(--clr-body);
    }
  }
}

@media screen and (min-width: 992px) {
  .card.wd-card .card-footer.footer-m-50 {
    justify-content: flex-start;
    width: 32%;
  }
}
@media screen and (max-width: 991.98px) {
  .card.wd-card .card-footer.footer-m-50 {
    width: 49%;
  }
}
@media screen and (max-width: 767.98px) {
  .card.wd-card .card-footer.footer-m-50 {
    flex-direction: column;
    width: 100%;
  }
  .card.wd-card .card-footer.footer-m-50 > * {
    width: 100%;
    flex: initial;
  }

  .cart-card {
    border: 1px solid #F1F1F2;
    border-radius: 8px;
    padding: 15px;
    min-height: 280px;
  
    .cart-card-header {
      padding-bottom: 15px;
      border-bottom: 1px dashed #E1E3EA;
  
      .row {
        align-items: center;
  
        h3 {
          font-size: 12px;
        }
    
        p {
          font-size: 10px;
        }
      }
    }
  }
}

.modal-card {
  border: 1px solid #d9d9d9 !important;
  border-radius: 0 !important;
}
