[dir="ltr"] {
  .flip-180 {
    transform: rotate(180deg);
  }

  // .bg-to-left {
  //     background: linear-gradient(
  //       to left,
  //       var(--clr-primary) 0%,
  //       var(--clr-primary) 50%,
  //       var(--clr-white) 50%,
  //       var(--clr-white) 100%
  //     ) !important;
  // }

  .progress-container .circle .caption {
    transform: translate(-45%, 0) !important;
  }
}
