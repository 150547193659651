/* Theme variables */
/* @import "../_theme-variables.css"; */

/*-----------*/
/* Font Files */

/* font weight lighter */
/* @font-face {
    font-family: "";
    src: url("./font-files/");
    src: url("./font-files/") format("embedded-opentype"),
         url("./font-files/") format("truetype"),
         url("./font-files/") format("svg");
    font-weight: 100;
    font-style: normal;
  } */
/* font weight light */
/* @font-face {
    font-family: "";
    src: url("./font-files/");
    src: url("./font-files/") format("embedded-opentype"),
         url("./font-files/") format("truetype"),
         url("./font-files/") format("svg");
    font-weight: 300;
    font-style: normal;
  } */
/* font weight normal */
/* @font-face {
    font-family: "";
    src: url("./font-files/");
    src: url("./font-files/") format("embedded-opentype"),
         url("./font-files/") format("truetype"),
         url("./font-files/") format("svg");
    font-weight: 400;
    font-style: normal;
  } */
/* font weight medium */
/* @font-face {
    font-family: "";
    src: url("./font-files/");
    src: url("./font-files/") format("embedded-opentype"),
         url("./font-files/") format("truetype"),
         url("./font-files/") format("svg");
    font-weight: 500;
    font-style: normal;
  } */
/* font weight bold */
/* @font-face {
    font-family: "";
    src: url("./font-files/");
    src: url("./font-files/") format("embedded-opentype"),
         url("./font-files/") format("truetype"),
         url("./font-files/") format("svg");
    font-weight: 700;
    font-style: normal;
  } */
/* font weight heavy */
/* @font-face {
    font-family: "";
    src: url("./font-files/");
    src: url("./font-files/") format("embedded-opentype"),
         url("./font-files/") format("truetype"),
         url("./font-files/") format("svg");
    font-weight: 900;
    font-style: normal;
  } */

/* Font URL || LTR Font */
/* @import url(var(--ff-ar-url)); */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

/* Font URL || RTL Font */
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@300;500&display=swap");
