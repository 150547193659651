/* Modal Overlay */

body .modal-backdrop {
  z-index: 1055 !important;
  background-color: rgba(0, 0, 0, 0.4);
  // backdrop-filter: blur(0.2rem) brightness(100%);
}

body .modal-backdrop.show {
  opacity: 1;
}
/* Modal General || Default */
.modal-dialog {
  .modal-content {
    padding: 0px 35px;
    background-color: #fff9ec !important;
    border: 0;
    border-radius: 8px;
  }
  &.modal-default {
    .modal-header {
      padding: 20px 0;
      justify-content: center;
      border-bottom: 1px solid #d9d9d9;

      .modal-title {
        font-size: 24px;
        font-weight: bold;
        line-height: 30px;
        padding: 0 20px;
        position: relative;

        &::before {
          inset-inline-start: -38px;
        }
        &::after {
          inset-inline-end: -38px;
        }
      }

      .modal-title2 {
        font-size: 24px;
        font-weight: bold;
        line-height: 30px;
        padding: 0 20px;
        position: relative;
      }

      .close-popup {
        position: absolute;
        width: 20px;
        right: 35px;
        cursor: pointer;
      }
    }
    .modal-body {
      padding: 0;
      padding-top: 25px;
    }
    .modal-footer {
      justify-content: center;
      border-top: 0;
      padding: 30px 0;
    }
  }

  &.modal-confirm {
    .modal-content {
      .modal-body {
        text-align: center;
        h3 {
          font-size: 28px;
          font-weight: bold;
          color: var(--clr-body);
        }
        p {
          font-size: 20px;
          font-weight: 500;
          color: var(--clr-secondary);
        }
      }
      .modal-footer {
        justify-content: center;
        border-top: 0;
        padding: 30px 0;
      }
    }
  }
}

// .modal-dialog .modal-content {
//     background-color: var(--clr-white);
//     border: 0.1rem solid var(--clr-primary);
//     border-radius: 1.5rem;
//     padding: 2rem;
//     font-size: 1.6rem;
//     font-weight: 400;
// }
// .modal-dialog .modal-header{padding: 0; border: 0;}
// .modal-header .modal-toolbar .btn-close-modal {outline: transparent;}
// .modal-header .modal-title{
//     display: inline-flex;
//     align-items: flex-end;
//     gap: 1rem;
//     padding-top: 1rem;
//     font-size: 2rem;
//     font-weight: 800;
//     line-height: 2.6rem;
// }
// .modal-header .modal-header-icon{
//     width: 3rem;
//     height: 3rem;
//     object-fit: contain;
//     background-size: contain;
//     background-position: center;
//     background-repeat: no-repeat;
// }
// .modal-header .modal-header-icon.icon-w-45{width: 4.5rem; height: 4.5rem;}
// .modal-header .modal-toolbar .btn-close-modal{
//     padding: 0;
//     border: 0;
//     background: none;
//     display: inline-flex;
//     align-items: center;
//     position: absolute;
//     top: 1rem;
//     inset-inline-end: 1rem;
//     width: 1.5rem;
//     height: 1.5rem;
//     color: var(--clr-primary);
// }
// .modal-dialog .modal-body {padding: 2.5rem 0;}
// .modal-dialog .modal-footer {
//     border: 0;
//     justify-content: center;
//     gap: 1rem;
//     padding: 1rem 0;
// }
// .modal-dialog .modal-footer > *{margin: 0;}
// .modal-footer .wd-btn {width: 100%}
// .modal-footer.footer-50 > * {flex: 1 0 0;}
// @media screen and (max-width: 550px) {
//     .modal-footer.footer-50 > * {flex: 100%;}
// }
// .modal-footer .btn-back{
//     background: 0;
//     border: 0;
//     color: var(--clr-gray);
// }
/* Modal || Confirmation */
// .modal-confirm .modal-body{
//     padding-top:4rem;
//     padding-bottom:2rem;
//     text-align: center;
// }
// .modal-confirm .modal-body h3{
//     font-size: 1.6rem;
//     font-weight: 700;
//     color: var(--clr-body);
//     line-height: 2.5rem;
// }

/* Modal || Toolbar */
/* .modal-headtoolbar .modal-content{
    padding: 2rem;
}
.modal-headtoolbar .modal-header{
    padding: 2rem 1.5rem;
    background-color: var(--clr-secondary);
    border-bottom: 0rem;
} */

// .modal-body .svg-icon-2-border{border-radius: 50%;}
// .modal-body .svg-icon-2-border svg{padding: 1rem; color: var(--clr-white);}

// .modal .dropdown .btn-dropdown {justify-content: flex-start;}
