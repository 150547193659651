/* === === Variables === === */
.table {
  --table-border-spacing: 0;
  --table-border-width: 1px;
  --table-bg: var(--clr-white);
  --table-border-clr: var(--clr-dark);
  --table-border-ss-radius: 10px;
  --table-border-se-radius: 10px;
  --table-border-es-radius: 10px;
  --table-border-ee-radius: 10px;
  --table-fw: 500;
  --table-mb: 20px;
  /* thead */
  --table-thead-valign: middle;
  --table-thead-th-padding-block: 10px;
  --table-thead-th-padding-inline: 10px;
  --table-thead-th-height: 40px;
  --table-thead-th-fs: 16px;
  --table-thead-th-fw: 700;
  --table-thead-th-lineheight: 18px;
  --table-thead-th-texttransform: uppercase;
  --table-thead-th-clr: var(--clr-dark);
  --table-thead-th-bg: transparent;
  --table-thead-th-borderbottom-width: 1px;
  --table-thead-th-borderbottom-clr: var(--clr-dark);
  --table-thead-th-borderend-width: 1px;
  --table-thead-th-borderend-clr: var(--clr-dark);
  /* tbody */
  --table-tbody-td-padding-block: 5px;
  --table-tbody-td-padding-inline: 5px;
  --table-tbody-td-height: 40px;
  --table-tbody-td-fs: 16px;
  --table-tbody-td-fw: 500;
  --table-tbody-td-lineheight: 22px;
  --table-tbody-td-texttransform: uppercase;
  --table-tbody-td-clr: var(--clr-dark);
  --table-tbody-td-bg: transparent;
  --table-tbody-td-borderbottom-width: 1px;
  --table-tbody-td-borderbottom-clr: var(--clr-dark);
  --table-tbody-td-borderend-width: 1px;
  --table-tbody-td-borderend-clr: var(--clr-dark);
  /* Action */
  --table-tbody-td-action-gap: 10px;
  /* Utility Classes  */
  --table-striped-tbody-td-evenrow-bg: var(--clr-secondary);
  --table-tbody-td-nodate-height: 100px;
  --table-tbody-td-nodate-clr: var(--clr-dark);
  --table-tbody-td-nodate-fs: 16px;
  --table-tbody-td-nodate-fw: 400;
  --table-tbody-td-nodate-bg: var(--clr-secondary);
}
/* === === Prefix === === */
.table.wd-table {
  --table-border-spacing: 0;
  --table-border-width: 0px;
  --table-bg: var(--clr-white);
  --table-fw: 500;
  --table-mb: 20px;
  /* thead */
  --table-thead-valign: middle;
  --table-thead-th-padding-block: 15px;
  --table-thead-th-padding-inline: 20px;
  --table-thead-th-height: 60px;
  --table-thead-th-fs: 16px;
  --table-thead-th-fw: 500;
  --table-thead-th-lineheight: 23px;
  --table-thead-th-clr: var(--clr-body);
  --table-thead-th-bg: transparent;
  --table-thead-th-borderbottom-width: 0px;
  --table-thead-th-borderend-width: 0px;
  /* tbody */
  --table-tbody-td-padding-block: 5px;
  --table-tbody-td-padding-inline: 20px;
  --table-tbody-td-height: 80px;
  --table-tbody-td-fs: 16px;
  --table-tbody-td-fw: 500;
  --table-tbody-td-lineheight: 23px;
  --table-tbody-td-clr: var(--clr-secondary);
  --table-tbody-td-bg: transparent;
  --table-tbody-td-borderbottom-width: 0px;
  --table-tbody-td-borderend-width: 0px;
  /* Utility Classes */
  --table-striped-tbody-td-evenrow-bg: var(--clr-gray-1);
  --table-tbody-td-nodate-height: 100px;
  --table-tbody-td-nodate-clr: var(--clr-dark);
  --table-tbody-td-nodate-fs: 20px;
  --table-tbody-td-nodate-fw: 500;
  --table-tbody-td-nodate-bg: var(--clr-secondary);

  thead.thead-primary {
    --table-thead-th-bg: var(--clr-primary);
    --table-thead-th-height: 50px;
    --table-thead-th-fs: 16px;
    --table-thead-th-fw: 700;
    --table-thead-th-lineheight: 21px;
    --table-thead-th-clr: var(--clr-white);
    --table-border-ss-radius: 6px;
    --table-border-se-radius: 6px;
  }
}
.table tbody td .table-action .btn.btn-icon.dropdown-toggle {
  width: 28px;
  height: 28px;
  padding: 5px;
  border-radius: 8px;
  background: var(--clr-gray-3);
  color: var(--clr-gray-2);
  min-width: auto;

  svg {
    font-size: 25px;
  }
}
/* ==== === ==== === ==== === ==== */
/* === === General Styling === === */
/* Table */
.table {
  border-collapse: separate;
  vertical-align: middle;
  background: var(--table-bg);
  border-spacing: var(--table-border-spacing);
  border: var(--table-border-width) solid var(--table-border-clr);
  border-bottom: 0px;
  border-start-start-radius: var(--table-border-ss-radius);
  border-start-end-radius: var(--table-border-se-radius);
  border-end-start-radius: var(--table-border-es-radius);
  border-end-end-radius: var(--table-border-ee-radius);
  font-weight: var(--table-fw);
  margin-bottom: var(--table-mb);
}
.table > *,
.table > * > * {
  border: 1px solid transparent;
}
.table > :not(caption) > * > * {
  box-shadow: none;
}
/* TABLE HEAD */
.table > thead {
  vertical-align: var(--table-thead-valign);
}
.table thead th {
  padding-block: var(--table-thead-th-padding-block);
  padding-inline: var(--table-thead-th-padding-inline);
  font-size: var(--table-thead-th-fs);
  line-height: var(--table-thead-th-lineheight);
  font-weight: var(--table-thead-th-fw);
  text-transform: var(--table-thead-th-texttransform);
  height: var(--table-thead-th-height);
  color: var(--table-thead-th-clr);
  background-color: var(--table-thead-th-bg);
  border-bottom: var(--table-thead-th-borderbottom-width) solid
    var(--table-thead-th-borderbottom-clr);
  border-inline-end: var(--table-thead-th-borderend-width) solid
    var(--table-thead-th-borderend-clr);
}
.table thead th:first-child {
  border-start-start-radius: var(--table-border-ss-radius);
}
.table thead th:last-child {
  border-start-end-radius: var(--table-border-se-radius);
  --table-thead-th-borderend-width: 0px;
}
.table thead th.table-action {
  width: 0;
}
/* TABLE BODY */
.table tbody td {
  padding-block: var(--table-tbody-td-padding-block);
  padding-inline: var(--table-tbody-td-padding-inline);
  font-size: var(--table-tbody-td-fs);
  line-height: var(--table-tbody-td-lineheight);
  font-weight: var(--table-tbody-td-fw);
  height: var(--table-tbody-td-height);
  color: var(--clr-black);
  background-color: var(--table-tbody-td-bg);
  border-bottom: var(--table-tbody-td-borderbottom-width) solid
    var(--table-tbody-td-borderbottom-clr);
  border-inline-end: var(--table-tbody-td-borderend-width) solid
    var(--table-tbody-td-borderend-clr);
}
.table tbody tr td:last-child {
  --table-tbody-td-borderend-width: 0px;
}
/* .table tbody tr:last-child td {--table-tbody-td-borderbottom-width: 0px;} */
.table tbody tr:last-child td:first-child {
  border-end-start-radius: var(--table-border-es-radius);
}
.table tbody tr:last-child td:last-child {
  border-end-end-radius: var(--table-border-ee-radius);
}
/* table action */
.table tbody td .table-action {
  display: inline-flex;
  align-items: center;
  gap: var(--table-tbody-td-action-gap);
}
.table tbody td .table-action .btn.btn-icon.btn-sm {
  min-width: var(--btn-icon-width-sm);
}
.table tbody td .table-action .btn.btn-icon,
.table tbody td .table-action .btn.btn-icon.btn-md {
  min-width: var(--btn-icon-width-md);
}
.table tbody td .table-action .btn.btn-icon.btn-lg {
  min-width: var(--btn-icon-width-lg);
}
.table tbody td .table-action .btn.btn-icon.btn-xl {
  min-width: var(--btn-icon-width-xl);
}
/* Utility classes */
.table.table-vertical-top tbody td {
  vertical-align: top;
}
.table thead.thead-light tr th {
  --table-thead-th-bg: var(--clr-secondary);
  --table-thead-th-clr: var(--clr-primary);
}
.table.table-striped thead:not(.thead-primary) ~ tbody tr:nth-child(odd) td {
  background-color: #fff;
  color: var(--table-tbody-td-clr);
}
.table.table-striped thead.thead-primary ~ tbody tr:nth-child(even) td {
  background-color: var(--table-striped-tbody-td-evenrow-bg);
  color: var(--clr-black);
}
.table tbody td.table-nodata {
  text-align: center;
  height: var(--table-tbody-td-nodate-height);
  background: var(--table-tbody-td-nodate-bg);
  color: var(--table-tbody-td-nodate-clr);
  font-size: var(--table-tbody-td-nodate-fs);
  font-weight: var(--table-tbody-td-nodate-fw);
  border: var(--table-tbody-td-borderbottom-width) solid
    var(--table-tbody-td-borderbottom-clr);
}
/* ==== === ==== === ==== === ==== */
/* CUSTOM Table Classes */

/* Table Bordered */
/* .table.table-bordered thead th,
.table.table-bordered tbody td {border-inline-end: 0.1rem solid var(--clr-secondary-3); border-inline-start: 0rem;}
.table.table-bordered thead th:last-child,
.table.table-bordered tbody td:last-child {border-inline-end: 0rem;}
.table.table-bordered tbody tr:last-child td {border-block-end: 0rem;} */
/* === ==== === */
/* .wd-table.table-bordered {border: 1px solid var(--clr-secondary-3); border-bottom: 0px; border-radius: 0.5rem;}
.wd-table.table-bordered tbody tr:last-child td:first-child {border-radius: 0 0 0.5rem 0;}
.wd-table.table-bordered tbody tr:last-child td:last-child {border-radius: 0 0 0 0.5rem;} */
/* === ==== === */
/* Prefix || Table */

/* === ==== === */
/* Table Components */
/* Table Form */
.table.wd-table input.form-control.wd-form-control {
  padding: 1rem 2rem;
  background-color: var(--clr-white);
  height: 3.5rem;
}
.table.wd-table .input-group.input-amount {
  max-width: 13rem;
}
.table.wd-table ng-select.form-control.wd-form-control {
  height: 3.5rem;
  padding: 1rem;
}
/* Table label */
@media screen and (min-width: 550px) {
  .table.wd-table .wd-label {
    padding-inline: 2rem;
  }
}
/* Table Dropdown */
.table.wd-table .dropdown {
  display: inline;
}
.table.wd-table .dropdown-menu,
body .dropdown-menu.table-dropdown {
  background-color: rgba(42, 61, 76, 0.95);
}
.table.wd-table .dropdown-menu:before,
.dropdown-menu.table-dropdown:before {
  content: none;
}
.table.wd-table .dropdown-menu button,
.table.wd-table .dropdown-menu > a,
.dropdown-menu.table-dropdown a,
.dropdown-menu.table-dropdown button {
  color: var(--clr-white) !important;
  justify-content: flex-start;
  white-space: nowrap;
}
.table.wd-table .dropdown-menu .btn-dropdown:not(.dropdown-toggle),
.dropdown-menu.table-dropdown .btn-dropdown:not(.dropdown-toggle) {
  margin: 0 !important;
}
.table.wd-table .btn-dropdown:not(.dropdown-toggle):hover,
.dropdown-menu.table-dropdown .btn-dropdown:not(.dropdown-toggle):hover {
  background: var(--clr-black);
}

.dropdown-menu.table-dropdown {
  position: absolute !important;
  inset-inline-end: 0;
}

/* NEW CODE || Dropdown Arrow */
.dropdown-menu.table-dropdown {
  top: 0.5rem !important;
  inset-inline-end: -2.5rem;
}
body .dropdown-menu.table-dropdown::before,
.table.wd-table .dropdown-menu.table-dropdown::before {
  content: "" !important;
  border-width: 0 0.7rem 0.6rem 0.7rem;
  top: -0.6rem;
  border-bottom-color: var(--clr-body);
}
.dropdown-menu.table-dropdown.dropdown-others {
  inset-inline-end: -2rem;
}

/* .table.wd-table .table-dropdown{inset-inline-end: -0.5rem;} */

/* Table that contain an editable elements */
.table.wd-table.editable-table {
  border: 0.1rem solid var(--clr-secondary);
}
.table.wd-table.editable-table tr td {
  background-color: var(--clr-gray-2) !important;
  border-top: 0.1rem solid var(--clr-secondary);
  transition-duration: 0.2s;
}
.table.wd-table.editable-table tbody tr:last-child td {
  border-bottom: 0;
}
/* Checkbox inside table */
.table.wd-table.editable-table tbody tr td .checkbox {
  margin-bottom: 0;
}
/* Hightlight row when checkbox is checked */
.table.wd-table.editable-table tbody tr.tr-active td {
  background-color: var(--clr-white) !important;
}

/* Special Responsive Table*/
/* @media screen and (min-width: 992px) {.special-responsive-table{overflow-x: auto;}} */
@media screen and (max-width: 991.98px) {
  .special-responsive-table .table.wd-table {
    border-bottom: 0.1rem solid var(--clr-secondary);
  }
  .special-responsive-table .table.wd-table thead {
    display: none;
  }
  .special-responsive-table .table.wd-table tbody tr {
    display: block;
    border-radius: 0rem;
    border-top: 0.1rem solid var(--clr-secondary);
  }
  .special-responsive-table .table.wd-table tbody tr td {
    position: relative;
    display: flex;
    align-items: center;
    height: auto;
    border: 0;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2.1rem;
    justify-content: space-between;
    background-color: var(--clr-white);
    padding-block: 1rem;
  }
  .special-responsive-table .table.wd-table tbody tr td:first-child {
    background-color: var(--clr-gray-2);
  }
  .special-responsive-table .table.wd-table tbody tr td:before {
    content: attr(data-tdname);
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 1.4rem;
    color: var(--clr-body);
  }
  .special-responsive-table .table.wd-table tbody tr td:first-child {
    background-color: var(--clr-gray-2);
  }
  /* .special-responsive-table.table-50 .table.wd-table tbody tr td > *{flex: 1; justify-content: flex-start;} */
  /* table 50 */
  .special-responsive-table.table-50 .table.wd-table tbody tr td {
    justify-content: flex-start;
    padding-inline-start: 50%;
    padding-inline-end: 0.5rem;
    min-height: 4.1rem;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  .special-responsive-table.table-50 .table.wd-table tbody tr td:before {
    position: absolute;
    inset-inline-start: 2rem;
  }
}
@media screen and (max-width: 550px) {
  .special-responsive-table.table-50 .table.wd-table tbody tr td {
    padding-inline-start: 41%;
  }
}
@media screen and (max-width: 600px) {
  .special-responsive-table.table-50 .table.wd-table tbody tr td.td-m-2-row {
    padding-top: 30px;
    padding-inline-start: 20px;
  }
  .special-responsive-table.table-50
    .table.wd-table
    tbody
    tr
    td.td-m-2-row:before {
    top: 8px;
  }
}

/* Lookup Tables */
.table.wd-table.editable-table .tr-active.lookup td > span,
.table.wd-table.editable-table tr:not(.tr-active.lookup) td > .form-control {
  display: none;
}
@media screen and (min-width: 992px) and (max-width: 1320px) {
  .special-responsive-table.table-overflow-xl-lg {
    overflow-y: auto;
  }
}
@media screen and (min-width: 992px) {
  .special-responsive-table.table-overflow-y-lg {
    overflow-y: auto;
  }
}
/* Aside Menu Scrollbar */
/* Firefox */
.special-responsive-table.table-overflow-y-lg {
  scrollbar-width: auto;
  scrollbar-color: var(--clr-gray-1) transparent;
}
/* Chrome, Edge, and Safari */
.special-responsive-table.table-overflow-y-lg::-webkit-scrollbar {
  width: 0.5rem;
}
.special-responsive-table.table-overflow-y-lg::-webkit-scrollbar-track {
  background: transparent;
}
.special-responsive-table.table-overflow-y-lg::-webkit-scrollbar-thumb {
  background: var(--clr-gray-1);
  border-radius: 0.5rem;
  width: 0.5rem;
}

/* Table Products || Notes Custom Class */
.table.wd-table.product-table {
  border: 0;
  border-spacing: 0 1rem;
  margin-bottom: 0rem;
}
.table.wd-table.product-table thead tr,
.table.wd-table.product-table thead tr th {
  background: none;
  border: 0;
}
.table.wd-table.product-table thead tr th:last-child,
.table.wd-table.product-table tbody tr td:last-child {
  text-align: end;
}
.table.wd-table.product-table tbody tr td {
  border-block: 1px solid var(--clr-secondary);
  border-inline: 0;
}
.table.wd-table.product-table tbody tr td:first-child {
  border-start-start-radius: 0.5rem;
  border-end-start-radius: 0.5rem;
  border-inline-start: 1px solid var(--clr-secondary);
}
.table.wd-table.product-table tbody tr td:last-child {
  border-start-end-radius: 0.5rem;
  border-end-end-radius: 0.5rem;
  border-inline-end: 1px solid var(--clr-secondary);
}
.table.wd-table.product-table tbody tr:nth-child(even) td {
  background-color: var(--clr-white);
}
.table.wd-table.product-table .checkbox > input:not(:checked) ~ span {
  background-color: var(--clr-secondary);
}
.wd-y-scroll {
  max-height: calc(100vh - 32.5rem);
  overflow-y: auto;
}

.sort-tbl {
  background-color: #F9FAFB;
  border-bottom: 1px solid #E5E7EB;
}

@media screen and (max-width: 400px) {
  .table.wd-table {
    width: 500%;
  }

  .w-300 {
    width: 290% !important;
  }
}
